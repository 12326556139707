import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '100%',
    minHeight: 'calc(100vh - 90px - 55px)',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',

    [theme.breakpoints.between('md', 'xl')]: {
      maxWidth: '80%',
    },

    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1200,
    },
  },
}));

export default makeClasses;
