import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  title: {
    fontSize: '3rem',
    lineHeight: '3.3rem',
    margin: '15px 0',
    position: 'relative',
    color: '#333',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      lineHeight: '2.2rem',
      margin: '10px 0',
    },
  },
  titleBold: {
    fontWeight: 'bold',
  },
  titleBoldWithLine: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '&::after': {
      content: '""',
      display: 'block',
      width: 60,
      height: 5,
      background: '#333',
      margin: '15px 0 0',
    },
  },
  titleLeftAligned: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  titleSm: {
    fontSize: '1.7rem',
    lineHeight: '2rem',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.3rem',
    },
  },
}));

export default makeClasses;
