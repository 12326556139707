import React from 'react';
import makeClasses from './styles';
import { Grid } from '@material-ui/core';
import Button from '../Button';
import Title from '../Title';

const HowItWorks = () => {
  const classes = makeClasses();

  return (
    <div className={classes.container}>
      <Title bold withLine>
        ¿Cómo funciona?
      </Title>
      <Grid container spacing={6} className={classes.howItWorksStepsWrapper}>
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.step}>
            <div className={classes.stepNumber}>
              <span>1</span>
            </div>
            <div className={classes.stepTitle}>Creá</div>
            <div className={classes.stepContent}>Iniciá una solicitud de match detallando que querés.</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.step}>
            <div className={classes.stepNumber}>
              <span>2</span>
            </div>
            <div className={classes.stepTitle}>Filtrá</div>
            <div className={classes.stepContent}>
              Podrás filtrar los matches que hayas recibido en base a las propuestas de los proveedores.
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.step}>
            <div className={classes.stepNumber}>
              <span>3</span>
            </div>
            <div className={classes.stepTitle}>Matcheá</div>
            <div className={classes.stepContent}>
              Una vez filtrados podrás elegir hacer match con 1 proveedor y te pondremos en contacto.
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowItWorks;
