import moment from 'moment';
import { getLocale } from './i18n';

export const inputs = {
  rut: {
    regex: /^[0-9]{12}$/,
    message: async () => {
      const i18n = await getLocale();
      return i18n.input_rut_error_message;
    },
  },
};

export const getInputTypes = () => {
  return inputs;
};

export const formatCurrency = (n, c, lang) => {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = lang === 'en' ? '.' : ',',
    t = lang === 'en' ? ',' : '.',
    s = n < 0 ? '-' : '',
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const formatCardNumber = (cardNumber) => {
  cardNumber = typeof cardNumber !== 'string' ? cardNumber.toString() : cardNumber;
  return `${cardNumber.substring(0, 4)} ${cardNumber.substring(4, 6)}XX XXXX XXXX`;
};

export const capitalize = (text) => {
  return `${text.substring(0, 1).toUpperCase()}${text.substring(1)}`;
};

export const getMonthDates = (month) => {
  const date = moment().month(month);
  let startDate = date.clone().startOf('month');
  let endDate = date.clone().endOf('month');

  var dates = [];
  var weeks = [];

  var perWeek = [];
  var difference = endDate.diff(startDate, 'days');

  perWeek.push(startDate.toDate());

  let index = 0;
  let lastWeek = false;
  while (startDate.add(1, 'days').diff(endDate) < 0) {
    if (startDate.day() !== 0) {
      perWeek.push(startDate.toDate());
    } else {
      if (startDate.clone().add(7, 'days').month() === month) {
        weeks.push(perWeek);
        perWeek = [];
        perWeek.push(startDate.toDate());
      } else if (Math.abs(index - difference) > 0) {
        if (!lastWeek) {
          weeks.push(perWeek);
          perWeek = [];
        }
        lastWeek = true;
        perWeek.push(startDate.toDate());
      }
    }

    index += 1;

    if (
      (lastWeek === true && Math.abs(index - difference) === 0) ||
      (Math.abs(index - difference) === 0 && perWeek.length === 1)
    ) {
      weeks.push(perWeek);
    }

    dates.push(startDate.toDate());
  }

  const startWeekDiff = 7 - weeks[0].length;
  for (let i = 0, l = startWeekDiff; i < l; i += 1) {
    weeks[0].unshift(null);
  }

  const endWeekDiff = 7 - weeks[weeks.length - 1].length;
  for (let i = 0, l = endWeekDiff; i < l; i += 1) {
    weeks[weeks.length - 1].push(null);
  }

  return weeks;
};

export const convertProfileToMatch = (profileId, profile) => {
  return {
    id: profileId,
    image: generateGradient(profile.email),
    full_image: profile.profile_image,
    category: profile.category,
    type: profile.type,
    type_name:
      profile.activity_type === 1
        ? 'Empresa'
        : profile.activity_type === 2
        ? 'Profesional independiente'
        : profile.activity_type === 3
        ? 'Independiente'
        : null,
    company_name: profile.company_name || '',
    company_activity_years: profile.company_activity_years || '',
    category_data: profile.category_data || {},
    experience: profile.experience || [],
    education: profile.education || [],
    brief: profile.brief || '',
    skills: profile.skills || [],
    rate: profile.rate || 0,
    matches: profile.matches || [],
    username: profile.username,
    email: profile.email,
    activity_modes: profile.activity_modes || [],
    department: profile.department || '',
    department_location: profile.department_location || '',
  };
};

export const convertProfileToWant = (profileId, matchId, profile, match) => {
  return {
    id: profileId,
    match_id: matchId,
    image: generateGradient(profile.email),
    full_image: profile.profile_image,
    type: profile.type,
    first_name: profile.first_name,
    last_name: profile.last_name,
    type_name: 'Usuario',
    user_rate: profile.user_rate || 0,
    provider_rate: profile.provider_rate || 0,
    matches: profile.matches || 0,
    username: profile.username,
    email: profile.email,
    match: {
      id: matchId,
      name: match.name,
      description: match.description,
      deadline: match.deadline,
      filters: match.filters,
      timestamp: match.timestamp,
      requires_budget: match.requires_budget || false,
      budget: match.budget || [],
      matches: match.matches || [],
    },
  };
};

// Simple hash function to generate a number from a string
function stringToHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

// Function to convert hash to RGB color
function hashToRGB(hash) {
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  return { r, g, b };
}

// Function to darken a color
function darkenColor(color, factor) {
  const r = Math.floor(color.r * factor);
  const g = Math.floor(color.g * factor);
  const b = Math.floor(color.b * factor);
  return `rgb(${r},${g},${b})`;
}

// Function to generate a gradient based on email
export const generateGradient = (email) => {
  const hash = stringToHash(email);
  const color = hashToRGB(hash);
  const darkColor = darkenColor(color, 0.6);
  const lightColor = `rgb(${color.r},${color.g},${color.b})`;

  return `linear-gradient(360deg, ${darkColor} 20%, ${lightColor} 80%)`;

  //return `linear-gradient(323deg, ${darkColor} 0%, ${color} 70%)`;
};
