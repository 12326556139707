import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  footerWrapper: {
    background: '#004D40',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '100%',
    height: 55,
  },
  footer: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    position: 'relative',
    fontSize: 14,
    color: 'white',
    width: '100%',

    '& a': {
      margin: '0 10px',
      color: 'white',

      '&:last-child': {
        marginRight: 0,
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-around',
    },

    [theme.breakpoints.between('md', 'xl')]: {
      maxWidth: '80%',
    },

    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1200,
    },
  },
}));

export default makeClasses;
