import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles(() => ({
  button: {
    padding: '9px 23px',
    border: 'none',
    borderRadius: 5,
    background: '#fff',
    border: '1px solid #fff',
    cursor: 'pointer',
    fontSize: 16,
    color: '#333',
    fontWeight: '400',
    transition: 'all 0.3s ease',
    fontWeight: '500',

    '&:hover': {
      opacity: 0.8,
    },

    '&[disabled]': {
      cursor: 'default',

      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  buttonWithIcon: {
    padding: '5px 10px',
  },
  buttonMd: {
    fontSize: 18,
    padding: '15px 23px',
  },
  buttonXs: {
    fontSize: 13,
    padding: '5px 15px',
  },
  buttonWithFullWidth: {
    width: '100%',
  },
  buttonGreen: {
    background: '#004D40',
    color: '#f5f5f5',
    border: '1px solid #004D40',
    fontWeight: '400',
  },
  buttonOrange: {
    background: '#FF6F00',
    color: '#fff',
    border: '1px solid #FF6F00',
    fontWeight: '400',
  },
  buttonYellow: {
    background: '#FFEB3B',
    color: '#333',
    border: '1px solid #FFEB3B',
    fontWeight: '400',
  },
  buttonOutlined: {
    border: '1px solid #555',
    fontWeight: '400',
  },
  buttonVariantDanger: {
    border: '1px solid #f44336',
    color: '#f44336',
    fontWeight: '400',
  },
  buttonVariantOrange: {
    borderColor: '#FF6F00',
    color: '#FF6F00',
  },
}));

export default makeClasses;
