import { useEffect, useState } from 'react';
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { useDispatch } from 'react-redux';
import { common as commonActions } from '../actions';

const firebaseConfig = {
  apiKey: 'AIzaSyBLVkLteZI45762kTkh66bSgHg2ddYWjOU',
  authDomain: 'quierohub.firebaseapp.com',
  projectId: 'quierohub',
  storageBucket: 'quierohub.appspot.com',
  messagingSenderId: '828764637072',
  appId: '1:828764637072:web:b2087de2e977920d8f5515',
  measurementId: 'G-780DMBY013',
};

const firebaseApp = initializeApp(firebaseConfig);

const useFirebase = () => {
  const dispatch = useDispatch();

  const [ready, setReady] = useState(false);

  const [app, setApp] = useState(null);
  const [auth, setAuth] = useState(null);
  const [db, setDb] = useState(null);
  const [storage, setStorage] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (auth) {
      onAuthStateChanged(auth, () => {
        if (auth.currentUser) {
          try {
            auth.currentUser.reload();
            setUser(auth.currentUser);
          } catch (error) {
            setUser(null);
          }
        } else {
          setUser(null);
        }
        setReady(true);
      });
    }
  }, [auth]);

  useEffect(() => {
    dispatch(commonActions.setLoading(true));
    if (!getApps().length) {
      setApp(firebaseApp);
      setAuth(getAuth(firebaseApp));
      setDb(getFirestore(firebaseApp));
      setStorage(getStorage(firebaseApp));
    } else {
      const app = getApp();
      setApp(app);
      setAuth(getAuth(app));
      setDb(getFirestore(app));
      setStorage(getStorage(app));
    }
    dispatch(commonActions.setLoading(false));
  }, []);

  const signOut = async () => {
    await auth.signOut();
    setAuth(null);
    setDb(null);
    setStorage(null);
    setUser(null);
  };

  return { app, auth, db, storage, user, ready, signOut };
};

export { firebaseApp };

export default useFirebase;
