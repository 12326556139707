import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  bubblesContainer: {
    width: 629,
    height: 62,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #ddd',
    borderRadius: 100,
    background: '#fff',
    overflow: 'hidden',

    '& button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      padding: 12,
      width: 80,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  placeholder: {
    background: '#004D40',
    color: '#fff',
    fontSize: 16,

    padding: '10px 20px 10px 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  input: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    padding: '0 10px',
  },
  formInputSelect: {
    padding: '0 !important',
    width: '100%',
    height: '100%',
    fontSize: 16,
    overflowY: 'auto',

    '&:before': {
      borderBottom: `none !important`,
    },
    '&:after': {
      borderBottom: `none !important`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `none !important`,
      },
    },
  },
  formInput: {
    width: '100%',

    '&:before': {
      borderBottom: `none !important`,
    },
    '&:after': {
      borderBottom: `none !important`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `none !important`,
      },
    },

    '& input': {
      padding: '0 !important',
      fontSize: 16,
      height: '100%',
    },

    '& div[class$="-endAdornment"]': {
      display: 'none',
    },

    '& fieldset': {
      border: 'none',
    },
  },
}));

export default makeClasses;
