import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  profileContainer: {
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  profileContainerContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 30,
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  profileContainerFooter: {
    padding: '15px 20px',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    background: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  profileSection: {
    marginBottom: 20,
  },
  profileSectionWithBorderBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    paddingBottom: 25,
  },
  profileSectionCentered: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 30px',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  profileSectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: Colors.black,
    marginBottom: 10,
    color: '#333',
  },
  profileSectionContent: {
    fontSize: 16,
    fontWeight: '300',
    color: '#555',
  },
  profileSectionImage: {
    width: 200,
    height: 200,
    borderRadius: 200,
    overflow: 'hidden',
    border: '1px solid rgba(0,0,0,0.1)',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
  profileSectionImageUser: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
  profileSectionInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  profileSectionInfoItemSeparator: {
    width: '100%',
    height: 1,
    background: 'rgba(0,0,0,0.1)',
    margin: '10px 0',
  },
  profileSectionInfoItemPosition: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.black,
  },
  profileSectionInfoItemCompanyName: {
    fontSize: 16,
    fontWeight: '300',
    color: '#555',
  },
  profileSectionInfoItemDates: {
    fontSize: 16,
    fontWeight: '300',
    color: '#999',
  },
  profileSectionInfoItemSummary: {
    fontSize: 16,
    fontWeight: '300',
    color: '#555',
    marginTop: 10,
  },
  profileSectionContentWithPills: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  profileSectionInfoItemPill: {
    background: '#f5f5f5',
    border: '1px solid rgba(0,0,0,0.1)',
    padding: '5px 10px',
    borderRadius: 5,
    fontSize: 14,
    fontWeight: '300',
    color: '#555',
    marginRight: 7,
    marginBottom: 5,

    '&:last-child': {
      marginRight: 0,
    },
  },
  imagesContainer: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
  },
  imageWrapper: {
    borderRadius: 7,
    border: '1px solid rgba(0,0,0,0.2)',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    marginRight: 10,

    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: 7,
    },

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export default makeClasses;
