import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    background: '#FFF3E0',
    padding: '40px 200px',
    textAlign: 'center',
    position: 'relative',

    '& > button': {
      marginTop: 50,
    },

    [theme.breakpoints.down('sm')]: {
      padding: '7%',
    },
  },
  hotItWroksCarrot: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '60%',
    transform: 'translate(0, 35%)',

    [theme.breakpoints.down('sm')]: {
      height: '50%',
      transform: 'translate(-35px, 50%)',
    },
  },
  howItWorksStepsWrapper: {
    height: '100%',
    marginTop: 40,
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10%',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  stepNumber: {
    width: 60,
    height: 60,
    borderRadius: 60,
    border: '5px solid #00796B',
    boxSizing: 'border-box',
    position: 'relative',

    '& > span': {
      color: '#FF6F00',
      fontWeight: '500',
      fontSize: '5rem',
      position: 'absolute',
      right: -10,
      top: -15,
    },
  },
  stepTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    margin: '37px 0 17px',
  },
  stepContent: {
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    fontWeight: '100',
  },
}));

export default makeClasses;
