import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware, push } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import * as reducers from '../reducers';
import rootSaga from '../sagas';
import { user as userActions } from '../actions';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  blacklist: ['router'],
};

export default (initialState = {}, history) => {
  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    connectRouter(history)(persistedReducer),
    applyMiddleware(sagaMiddleware),
    compose(...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store, null, () => {
    try {
      store.dispatch(userActions.setLoading(false));

      const state = store.getState();
      if (state?.user?.token) {
        store.dispatch(userActions.initialize());
        if (/login|register/.test(window.location.pathname)) {
          if (!state?.user?.data?.profile_completed) {
            store.dispatch(push('/profile'));
          } else {
            store.dispatch(push('/home'));
          }
        }
      } else {
        if (!/|login|register|search/.test(window.location.pathname)) {
          store.dispatch(push('/'));
          store.dispatch(push(userActions.logOut()));
        }
      }
    } catch (e) {
      store.dispatch(push('/'));
      store.dispatch(push(userActions.logOut()));
    }
  });

  return { store, persistor };
};
