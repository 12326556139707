import React, { useEffect, useState } from 'react';
import { Button, Container, Header, Page, TextInput, Title } from '../../components';
import makeClasses from './styles';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { common as commonActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import { addDoc, collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MatchByCategoryScreen = ({ match }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { auth, db } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();

  const [matchName, setMatchName] = useState('');
  const [matchDescription, setMatchDescription] = useState('');
  const [matchDeadline, setMatchDeadline] = useState('');
  const [matchFilters, setMatchFilters] = useState([]);
  const [matchRequiresBudget, setMatchRequiresBudget] = useState(false);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);
  const [categoryId, setCategoryId] = useState(match.params.categoryId || 'new');

  const canSubmitForm = matchName && matchDescription;

  useEffect(() => {
    if (db) {
      if (!categoryId || categoryId === 'new') {
        getCategories();
      } else {
        getData();
      }
    }
  }, [db, categoryId]);

  const getCategories = async () => {
    dispatch(commonActions.setLoading(true));
    const categories = await getDocs(query(collection(db, 'categories'), where('providers', '>', 0)));
    setCategories(
      _.orderBy(
        categories.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ['quiero_name'],
        ['asc']
      )
    );
    dispatch(commonActions.setLoading(false));
  };

  const getData = async () => {
    dispatch(commonActions.setLoading(true));
    await getFilters();
    await getCategory();
    dispatch(commonActions.setLoading(false));
  };

  const getFilters = async () => {
    const docs = await getDocs(
      query(
        collection(db, 'users'),
        where('category', '==', categoryId),
        where('active', '==', true),
        where('in_review', '==', false)
      )
    );
    let skills = [];
    docs.forEach((doc) => {
      const docData = doc.data();
      skills = skills.concat(docData.skills.map((s) => s.name));
    });
    setFilters(_.orderBy(_.uniq(skills), [], ['asc']));
  };

  const getCategory = async () => {
    if (!categoryId || categoryId === 'new') {
      return;
    }
    const categoryRef = await getDoc(doc(collection(db, 'categories'), categoryId));
    if (categoryRef.exists()) {
      setCategory({ id: categoryRef.id, ...categoryRef.data() });
    } else {
      setCategoryId('new');
    }
  };

  const handleOnChangeCategory = (e, value) => {
    setCategoryId(value);
  };

  const goToMatches = () => {
    dispatch(push('/home?tab=1'));
  };

  const handleOnGoBack = () => {
    dispatch(goBack());
  };

  const handleOnClickMatch = async () => {
    dispatch(commonActions.setLoading(true));

    try {
      await addDoc(collection(db, 'matches'), {
        user_id: auth.currentUser.uid,
        category_id: categoryId,
        name: matchName,
        description: matchDescription,
        deadline: matchDeadline,
        status: 'active',
        timestamp: new Date(),
        filters: matchFilters,
        matches: [],
        requires_budget: matchRequiresBudget,
        type: 0, // group match
      });

      notifySuccess('¡Enviamos tu solicitud de match!');

      setTimeout(() => {
        goToMatches();
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const getOptionLabel = () => (option) => {
    return categories.find((c) => c.id === option)?.quiero_name || '';
  };

  const renderForm = () => {
    return (
      <div className={classes.stepContent}>
        <div className={categoryId === 'new' ? classes.titleWrapper : null}>
          <Title bold leftAligned size="sm">
            Encontremos match para: {categoryId !== 'new' ? category?.quiero_name : ''}
          </Title>
          {categoryId === 'new' ? (
            <Autocomplete
              style={{ width: '100%' }}
              options={categories.map((c) => c.id)}
              getOptionLabel={getOptionLabel()}
              onChange={handleOnChangeCategory}
              renderInput={(params) => <TextInput {...params} label="Seleccioná un quiero" variant="outlined" />}
            />
          ) : null}
        </div>
        {categoryId !== 'new' ? (
          <React.Fragment>
            <div className={classes.stepMessage}>
              <Alert severity="info">
                Para encontrar match, intenta describir con el mayor nivel de detalle lo que querés.
              </Alert>
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  label="¿Cuál es tu quiero?"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Describí tu quiero en una palabra o una frase corta"
                  value={matchName}
                  onChange={(e) => setMatchName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  label="Describí tu quiero"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={15}
                  placeholder="Contá (con el mayor nivel de detalle que puedas) qué querés"
                  value={matchDescription}
                  onChange={(e) => setMatchDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  label="¿Tenés una fecha límite?"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  limitMinDate
                  value={matchDeadline}
                  onChange={(e) => setMatchDeadline(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControlLabel
                  labelPlacement="start"
                  style={{ color: '#333' }}
                  control={
                    <Checkbox
                      checked={matchRequiresBudget}
                      onChange={(e) => setMatchRequiresBudget(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="¿Requiere presupuesto?"
                />
              </Grid>
              {filters?.length > 0 ? (
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.stepInfoMessage}>
                    Elegí filtros para condicionar la búsqueda y lograr filtrar los matches que más se ajusten a tu
                    quiero.
                  </div>
                  <Autocomplete
                    options={filters}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => setMatchFilters(value)}
                    renderInput={(params) => (
                      <TextInput {...params} label="Seleccioná filtros para mejorar tu match" variant="outlined" />
                    )}
                    multiple
                  />
                </Grid>
              ) : null}
            </Grid>
          </React.Fragment>
        ) : null}
        <div className={classes.actionsWrapper}>
          <Button type="outlined" onClick={handleOnGoBack}>
            Cancelar
          </Button>
          {categoryId !== 'new' ? (
            <Button type="green" onClick={handleOnClickMatch} disabled={!canSubmitForm}>
              Confirmar
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <Page>
      <Header withBorder showSearchBar={false} />
      <Container>
        <div className={classes.container}>{renderForm()}</div>
      </Container>
    </Page>
  );
};

MatchByCategoryScreen.id = 'com.QuieroMatch.MatchByCategory';

export default MatchByCategoryScreen;
