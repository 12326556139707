import React, { useEffect, useState } from 'react';
import { Button, Container, Header, MatchCard, MatchProfile, Page, TextInput, Title } from '../../components';
import makeClasses from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Slider, Typography } from '@material-ui/core';
import { common as commonActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import _ from 'lodash';
import { convertProfileToMatch, convertProfileToWant } from '../../utils/Utils';

const RateMatchScreen = ({ match }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { db } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();
  const matchId = match.params.matchId || null;

  const userData = useSelector((state) => state.user.data);

  const [inputRate, setInputRate] = useState(3);
  const [inputRateNPS, setInputRateNPS] = useState(8);
  const [inputRateComment, setInputRateComment] = useState('');
  const [matchProfileData, setMatchProfileData] = useState(null);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [matchData, setMatchData] = useState(null);

  const canSubmitForm = inputRateComment && inputRate;

  useEffect(() => {
    if (db) {
      getData();
    }
  }, [db, matchId]);

  const getData = async () => {
    dispatch(commonActions.setLoading(true));

    const matchRef = await getDoc(doc(collection(db, 'matches'), matchId));
    const matchData = matchRef.data();

    if (userData?.type === 0) {
      const matchProfileRef = await getDoc(doc(collection(db, 'users'), matchData.profile_id));
      const matchProfileData = matchProfileRef.data();
      setMatchProfileData(convertProfileToMatch(matchProfileRef.id, matchProfileData));
    } else {
      const matchProfileRef = await getDoc(doc(collection(db, 'users'), matchData.user_id));
      const matchProfileData = matchProfileRef.data();
      setMatchProfileData(convertProfileToWant(matchProfileRef.id, matchRef.id, matchProfileData, matchData));
    }

    setMatchData({ id: matchRef.id, ...matchData });

    dispatch(commonActions.setLoading(false));
  };

  const goToMatches = () => {
    dispatch(push('/home?tab=0'));
  };

  const handleOnRate = async () => {
    dispatch(commonActions.setLoading(true));

    try {
      if (userData?.type === 0) {
        await updateDoc(doc(db, 'matches', matchData.id), {
          user_rated_date: Date.now(),
          user_rate: inputRate > 5 ? 5 : inputRate < 1 ? 1 : inputRate,
          user_rate_nps: inputRateNPS > 10 ? 10 : inputRateNPS < 1 ? 1 : inputRateNPS,
          user_rate_comment: inputRateComment,
        });
      } else {
        await updateDoc(doc(db, 'matches', matchData.id), {
          provider_rated_date: Date.now(),
          provider_rate: inputRate > 5 ? 5 : inputRate < 1 ? 1 : inputRate,
          provider_rate_comment: inputRateComment,
        });
      }

      notifySuccess('Tu calificación se guardó correctamente');

      setTimeout(() => {
        goToMatches();
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const renderForm = () => {
    return (
      <div className={classes.stepContent}>
        <Title bold leftAligned size="sm">
          Calificá tu experiencia
        </Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            {matchProfileData ? (
              <MatchCard
                match={matchProfileData}
                onClickProfile={() => setShowProfileInfo(true)}
                readOnly
                fullProfile
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" gutterBottom>
                  ¿Cómo calificarías tu experiencia?
                </Typography>
                <TextInput
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={3}
                  placeholder="Ayuda a otros usuarios a conocer tu experiencia"
                  value={inputRateComment}
                  onChange={(e) => setInputRateComment(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="body2" gutterBottom>
                  Del 1 al 5, ¿Cuál es tu nivel de satisfacción con el match?
                </Typography>
                <div className={classes.sliderContainer}>
                  <Slider
                    defaultValue={3}
                    value={inputRate}
                    step={1}
                    min={1}
                    max={5}
                    marks={false}
                    onChange={(e, value) => setInputRate(value)}
                  />
                  <Typography>{inputRate}</Typography>
                </div>
              </Grid>
              {userData?.type === 0 ? (
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="body2" gutterBottom>
                    Del 1 al 10, ¿Qué tan probable es que recomiendes a este proveedor a tus colegas o amigos?
                  </Typography>
                  <div className={classes.sliderContainer}>
                    <Slider
                      defaultValue={8}
                      value={inputRateNPS}
                      step={1}
                      min={1}
                      max={10}
                      marks={false}
                      onChange={(e, value) => setInputRateNPS(value)}
                    />
                    <Typography>{inputRateNPS}</Typography>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.actionsWrapper}>
          <Button type="outlined" onClick={goToMatches}>
            Cancelar
          </Button>
          <Button type="green" onClick={handleOnRate} disabled={!canSubmitForm}>
            Confirmar
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Page>
      <Header withBorder showSearchBar={false} />
      <Container>
        <div className={classes.container}>{renderForm()}</div>
      </Container>
      <MatchProfile
        open={showProfileInfo}
        onClose={() => setShowProfileInfo(false)}
        readOnly
        profile={matchProfileData}
        fullProfile
        categoryId={matchData?.category_id}
      />
    </Page>
  );
};

RateMatchScreen.id = 'com.QuieroMatch.RateMatch';

export default RateMatchScreen;
