import React from 'react';
import { useSelector } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import makeClasses from './styles';

function Footer() {
  const { loading } = useSelector((state) => ({ loading: state.common.loading }));
  const classes = makeClasses();

  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footer}>
        <div>Quiero Match © 2024</div>
        <div>
          <a href="/terms">Términos y condiciones</a>
          <a href="/privacy">Política de privacidad</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
