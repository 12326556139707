import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  brandLogo: {
    height: 54,
    marginTop: 210,
    marginBottom: 35,

    [theme.breakpoints.down('sm')]: {
      height: 34,
    },
  },
  heroContainer: {
    height: 'calc(100vh - 64px - 136px)',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 64px - 136px)',
    },
  },
  titleBold: {
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '2.2rem',
    margin: '15px 0',
    position: 'relative',
    color: '#333',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.1rem',
      margin: '10px 0',
    },
  },
  contentText: {
    fontWeight: '100',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    margin: '15px 0',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      lineHeight: '1.5rem',
      margin: '10px 0',
    },
  },
  cta: {
    background: '#004D40',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    height: 100,

    '& > div': {
      color: '#fff !important',
      fontSize: 24,
      fontWeight: '500',
    },

    [theme.breakpoints.down('sm')]: {
      '& > div': {
        fontSize: 20,
      },
    },
  },
  slogan: {
    paddingTop: 96,
    fontSize: 55,
    fontWeight: 'bold',
    color: '#004D40',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 56,
    textAlign: 'center',

    '& > span': {
      '&:last-child': {
        marginTop: -15,
      },
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 45,
      marginBottom: 30,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 34,
      marginBottom: 30,
    },
  },
  searchBarWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));

export default makeClasses;
