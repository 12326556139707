import { Drawer, Grid, useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react';
import makeClasses from './styles';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';
import Button from '../Button';
import TextInput from '../TextInput';
import { useSelector } from 'react-redux';
import { useFirebase } from '../../hooks';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const departments = [
  { id: 1, name: 'Artigas', locations: ['Artigas', 'Bella Unión', 'Tomás Gomensoro', 'Baltasar Brum'] },
  {
    id: 2,
    name: 'Canelones',
    locations: [
      'Canelones',
      'Las Piedras',
      'Pando',
      'Ciudad de la Costa',
      'La Paz',
      'Progreso',
      'Santa Lucía',
      'Sauce',
      'Tala',
      'Toledo',
      'Atlántida',
      'Empalme Olmos',
    ],
  },
  { id: 3, name: 'Cerro Largo', locations: ['Melo', 'Río Branco', 'Fraile Muerto', 'Aceguá'] },
  {
    id: 4,
    name: 'Colonia',
    locations: ['Colonia del Sacramento', 'Carmelo', 'Nueva Helvecia', 'Rosario', 'Nueva Palmira', 'Juan Lacaze'],
  },
  { id: 5, name: 'Durazno', locations: ['Durazno', 'Sarandí del Yí', 'Villa del Carmen'] },
  { id: 6, name: 'Flores', locations: ['Trinidad', 'Ismael Cortinas'] },
  { id: 7, name: 'Florida', locations: ['Florida', 'Sarandí Grande', '25 de Agosto'] },
  { id: 8, name: 'Lavalleja', locations: ['Minas', 'José Pedro Varela', 'Solís de Mataojo'] },
  {
    id: 9,
    name: 'Maldonado',
    locations: ['Maldonado', 'Punta del Este', 'San Carlos', 'Piriápolis', 'Pan de Azúcar', 'Aiguá'],
  },
  { id: 10, name: 'Montevideo', locations: ['Montevideo'] },
  { id: 11, name: 'Paysandú', locations: ['Paysandú', 'Guichón', 'Quebracho'] },
  { id: 12, name: 'Río Negro', locations: ['Fray Bentos', 'Young', 'San Javier', 'Nuevo Berlín'] },
  { id: 13, name: 'Rivera', locations: ['Rivera', 'Tranqueras', 'Vichadero'] },
  { id: 14, name: 'Rocha', locations: ['Rocha', 'Chuy', 'Castillos', 'Lascano', 'La Paloma'] },
  { id: 15, name: 'Salto', locations: ['Salto', 'Constitución', 'Belén'] },
  {
    id: 16,
    name: 'San José',
    locations: ['San José de Mayo', 'Ciudad del Plata', 'Libertad', 'Ecilda Paullier', 'Rodríguez'],
  },
  { id: 17, name: 'Soriano', locations: ['Mercedes', 'Dolores', 'Cardona', 'José Enrique Rodó', 'Palmitas'] },
  { id: 18, name: 'Tacuarembó', locations: ['Tacuarembó', 'Paso de los Toros', 'San Gregorio de Polanco'] },
  { id: 19, name: 'Treinta y Tres', locations: ['Treinta y Tres', 'Vergara', 'Santa Clara de Olimar'] },
];

const MatchProfile = ({ open, profile, onClose, onMatch, categoryId, readOnly = false }) => {
  const classes = makeClasses();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [inputBudget, setInputBudget] = useState('');
  const { user } = useFirebase();
  const skills = profile?.category_data
    ? profile.category_data?.[categoryId]?.skills || profile?.skills || []
    : profile?.skills || [];
  const education = profile?.category_data
    ? profile.category_data?.[categoryId]?.education || profile?.education || []
    : profile?.education || [];
  const experience = profile?.category_data
    ? profile.category_data?.[categoryId]?.experience || profile?.experience || []
    : profile?.experience || [];
  const images = profile?.category_data ? profile.category_data?.[categoryId]?.images || [] : [];

  const handleOnMatch = () => {
    if (profile?.match?.requires_budget) {
      onMatch(profile, inputBudget);
    } else {
      onMatch(profile);
    }
  };

  const renderProfile = () => {
    if (!profile) {
      return null;
    }

    if (profile.type === 0) {
      const timePassed = moment().diff(moment(profile?.match?.timestamp?.toDate()), 'minutes');
      const timePassedFormatted = moment.duration(timePassed, 'minutes').humanize();

      return (
        <React.Fragment>
          <div className={classes.profileSection}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={clsx(classes.profileSectionImage, classes.profileSectionImageUser)}>
                  <img src={profile.full_image} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.profileSectionTitle}>Nombre</div>
                    <div className={classes.profileSectionContent}>
                      {profile.first_name} {profile.last_name}
                    </div>
                  </Grid>
                  {profile.department ? (
                    <Grid item xs={12} sm={12} md={6}>
                      <div className={classes.profileSectionTitle}>Departamento</div>
                      <div className={classes.profileSectionContent}>{profile.department}</div>
                    </Grid>
                  ) : null}
                  {profile.department_location ? (
                    <Grid item xs={12} sm={12} md={6}>
                      <div className={classes.profileSectionTitle}>Localidad</div>
                      <div className={classes.profileSectionContent}>{profile.department_location}</div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.profileSection}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.profileSectionTitle}>¿Qué quiere?</div>
                <div className={classes.profileSectionContent}>
                  <TextInput value={profile?.match?.name} disabled />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.profileSectionTitle}>Descripción de lo que quiere</div>
                <div className={classes.profileSectionContent}>
                  <TextInput multiline value={profile?.match?.description} rows={15} disabled />
                </div>
              </Grid>
              {profile?.match?.deadline ? (
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.profileSectionTitle}>Valido hasta</div>
                  <div className={classes.profileSectionContent}>
                    {moment(profile?.match?.deadline).format('DD, MMM YYYY')}
                  </div>
                </Grid>
              ) : null}
              {timePassedFormatted ? (
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.profileSectionTitle}>Tiempo transcurrido desde que se creó</div>
                  <div className={classes.profileSectionContent}>{_.capitalize(timePassedFormatted)}</div>
                </Grid>
              ) : null}
              {profile?.match?.filters?.length > 0 ? (
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.profileSectionTitle}>Habilidades / Rubros / Actividades requeridos</div>
                  <div className={clsx(classes.profileSectionContent, classes.profileSectionContentWithPills)}>
                    {profile?.match?.filters.map((filter, index) => (
                      <div key={index} className={classes.profileSectionInfoItemPill}>
                        {filter}
                      </div>
                    ))}
                  </div>
                </Grid>
              ) : null}
              {profile?.match?.requires_budget ? (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.profileSectionTitle}>¿Requiere presupuesto?</div>
                    <div className={classes.profileSectionContent}>Si</div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.profileSectionTitle}>Tu presupuesto</div>
                    <div className={classes.profileSectionContent}>
                      <TextInput
                        placeholder="Presupuesto"
                        multiline
                        rows={3}
                        value={profile?.match?.budget?.find((b) => b.user_id === user.uid)?.budget || ''}
                        onChange={(e) => setInputBudget(e.target.value)}
                        disabled={readOnly}
                      />
                    </div>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </div>
        </React.Fragment>
      );
    }

    if (profile.type === 1) {
      return (
        <React.Fragment>
          <div className={clsx(classes.profileSection, classes.profileSectionWithBorderBottom)}>
            <Grid container spacing={3} alignItems="center" justify="flex-start">
              <Grid item xs={12} sm={12} md={4}>
                <div className={classes.profileSectionImage}>
                  <img src={profile.full_image} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.profileSectionTitle}>Nombre</div>
                    <div className={classes.profileSectionContent}>{profile.company_name}</div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.profileSectionTitle}>Tipo de actividad</div>
                    <div className={classes.profileSectionContent}>{profile.type_name}</div>
                  </Grid>
                  {profile.company_activity_years ? (
                    <Grid item xs={12} sm={12} md={6}>
                      <div className={classes.profileSectionTitle}>Años de actividad</div>
                      <div className={classes.profileSectionContent}>{profile.company_activity_years}</div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </div>
          {profile.brief ? (
            <div className={classes.profileSection}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.profileSectionTitle}>¿Por qué es un buen match?</div>
                  <div className={classes.profileSectionContent}>{profile.brief}</div>
                </Grid>
              </Grid>
            </div>
          ) : null}
          {images.length > 0 ? (
            <div className={classes.profileSection}>
              <div className={classes.profileSectionTitle}>Top 5 trabajos realizados</div>
              <div className={classes.profileSectionContent}>
                <PhotoProvider>
                  <div className={classes.imagesContainer}>
                    {images.map((img, index) => (
                      <div className={classes.imageWrapper} key={index}>
                        <PhotoView src={img}>
                          <img src={img} />
                        </PhotoView>
                      </div>
                    ))}
                  </div>
                </PhotoProvider>
              </div>
            </div>
          ) : null}
          {skills?.length > 0 ? (
            <div className={classes.profileSection}>
              <div className={classes.profileSectionTitle}>Habilidades / Rubros / Actividades</div>
              <div className={clsx(classes.profileSectionContent, classes.profileSectionContentWithPills)}>
                {skills.map((skill, index) => (
                  <div key={index} className={classes.profileSectionInfoItemPill}>
                    {skill.name || skill}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className={classes.profileSection}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.profileSectionTitle}>Modalidad de trabajo</div>
                <div className={clsx(classes.profileSectionContent, classes.profileSectionContentWithPills)}>
                  {profile.activity_modes?.length > 0
                    ? profile.activity_modes?.map((mode, index) => (
                        <div key={index} className={classes.profileSectionInfoItemPill}>
                          {mode === 1 ? 'Remoto' : mode === 2 ? 'Presencial' : 'No especificado'}
                        </div>
                      ))
                    : 'No especificado'}
                </div>
              </Grid>
            </Grid>
          </div>
          {profile.department ? (
            <div className={classes.profileSection}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.profileSectionTitle}>Ubicación</div>
                  <div className={classes.profileSectionContent}>
                    {departments.find((d) => d.id === profile.department)?.name || ''}
                    {profile.department_location ? `, ${profile.department_location}` : ''}
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : null}
          {experience?.length > 0 ? (
            <div className={classes.profileSection}>
              <div className={classes.profileSectionTitle}>Experiencia</div>
              <div className={classes.profileSectionContent}>
                {experience.map((experience, index) => (
                  <React.Fragment key={index}>
                    <div className={classes.profileSectionInfoItem}>
                      {experience.position ? (
                        <div className={classes.profileSectionInfoItemPosition}>{experience.position}</div>
                      ) : null}
                      {experience.company_name ? (
                        <div className={classes.profileSectionInfoItemCompanyName}>{experience.company_name}</div>
                      ) : null}
                      {experience.start_date && experience.end_date ? (
                        <div className={classes.profileSectionInfoItemDates}>
                          {_.capitalize(moment(experience.start_date).format('MMM YYYY'))} -{' '}
                          {_.capitalize(moment(experience.end_date).format('MMM YYYY'))}
                        </div>
                      ) : null}
                      {experience.start_date && !experience.end_date ? (
                        <div className={classes.profileSectionInfoItemDates}>
                          {_.capitalize(moment(experience.start_date).format('MMM YYYY'))} -
                        </div>
                      ) : null}
                      {experience.description ? (
                        <div className={classes.profileSectionInfoItemSummary}>{experience.description}</div>
                      ) : null}
                    </div>
                    {index < profile.experience.length - 1 ? (
                      <div className={classes.profileSectionInfoItemSeparator} />
                    ) : null}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : null}
          {education?.length > 0 ? (
            <div className={classes.profileSection}>
              <div className={classes.profileSectionTitle}>Educación / Formación</div>
              <div className={classes.profileSectionContent}>
                {education.map((education, index) => (
                  <React.Fragment key={index}>
                    <div className={classes.profileSectionInfoItem}>
                      {education.institution_name ? (
                        <div className={classes.profileSectionInfoItemPosition}>{education.institution_name}</div>
                      ) : null}
                      {education.degree ? (
                        <div className={classes.profileSectionInfoItemCompanyName}>{education.degree}</div>
                      ) : null}
                      {education.start_date && education.end_date ? (
                        <div className={classes.profileSectionInfoItemDates}>
                          {_.capitalize(moment(education.start_date).format('MMM YYYY'))} -{' '}
                          {_.capitalize(moment(education.end_date).format('MMM YYYY'))}
                        </div>
                      ) : null}
                      {education.start_date && !education.end_date ? (
                        <div className={classes.profileSectionInfoItemDates}>
                          {_.capitalize(moment(education.start_date).format('MMM YYYY'))} -
                        </div>
                      ) : null}
                      {education.description ? (
                        <div className={classes.profileSectionInfoItemSummary}>{education.description}</div>
                      ) : null}
                    </div>
                    {index < profile.education.length - 1 ? (
                      <div className={classes.profileSectionInfoItemSeparator} />
                    ) : null}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  };

  return (
    <Drawer
      anchor={isSmallScreen ? 'bottom' : 'right'}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.profileContainer }}
    >
      <div className={classes.profileContainerContent}>{renderProfile()}</div>
      <div className={classes.profileContainerFooter}>
        <Button type="outlined" onClick={onClose}>
          Cerrar
        </Button>
        {/* {!readOnly ? (
          <Button
            type="green"
            onClick={handleOnMatch}
            disabled={profile?.match?.requires_budget ? !inputBudget : false}
          >
            Matchear
          </Button>
        ) : null} */}
      </div>
    </Drawer>
  );
};

export default MatchProfile;
