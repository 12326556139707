import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    height: 32,

    [theme.breakpoints.down('sm')]: {
      height: 44,
    },
  },
}));

export default makeClasses;
