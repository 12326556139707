import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    minHeight: '100%',
    padding: '100px 20px',
    width: 500,
    margin: '0 auto',
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  headerContainer: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  title: {
    color: '#333',
    margin: '15px 0',
    position: 'relative',
    fontSize: 32,
    textAlign: 'center',
    fontWeight: '400',
  },
  loginFormActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 20,
  },
  loginFormRegisterCta: {
    marginTop: 15,
    marginBottom: 20,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > span': {
      display: 'block',
      marginRight: 5,
      color: '#333',
    },

    '& > a': {
      color: '#004D40',
    },
  },
  loginFormRegisterLogin: {
    marginTop: 15,
    marginBottom: 20,
    fontSize: 14,

    '& > span': {
      display: 'block',
      marginBottom: 5,
      color: '#333',
    },
  },
}));

export default makeClasses;
