import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  inputContainer: {
    width: '100%',
    position: 'relative',
  },
  input: {
    width: '100%',

    '& input': {
      color: '#555',
    },
    '& textarea': {
      color: '#555',
    },
  },
  inputWithEndAdornment: {
    '& input': {
      paddingRight: '40px !important',
    },
  },
  inputEndAdornment: {
    position: 'absolute',
    right: 0,
    top: 29,
  },
}));

export default makeClasses;
