import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: '#888',
    fontWeight: '100',
  },
}));

export default makeClasses;
