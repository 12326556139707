import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Header,
  MatchCard,
  MatchDialog,
  MatchProfile,
  Page,
  TextInput,
  Title,
} from '../../components';
import makeClasses from './styles';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { common as commonActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { convertProfileToMatch } from '../../utils/Utils';

const MatchScreen = ({ match }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { auth, db } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();
  const profileId = match.params.profileId || null;
  const searchParams = new URLSearchParams(window.location.search);

  const [showMatchDialog, setShowMatchDialog] = useState(false);
  const [matchName, setMatchName] = useState('');
  const [matchDescription, setMatchDescription] = useState('');
  const [matchDeadline, setMatchDeadline] = useState('');
  const [matchProfileData, setMatchProfileData] = useState(null);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [category, setCategory] = useState(null);

  const canSubmitForm = matchName && matchDescription;

  useEffect(() => {
    if (db) {
      getData();
    }
  }, [db, profileId]);

  const getData = async () => {
    if (!searchParams.get('category')) {
      return;
    }

    try {
      dispatch(commonActions.setLoading(true));

      const matchProfileRef = await getDoc(doc(collection(db, 'users'), profileId));
      const matchProfileData = matchProfileRef.data();
      setMatchProfileData(convertProfileToMatch(matchProfileRef.id, matchProfileData));

      const categoryRef = await getDoc(doc(collection(db, 'categories'), searchParams.get('category')));
      setCategory(categoryRef.data().quiero_name);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const goToMatches = () => {
    dispatch(push('/home?tab=0'));
  };

  const handleOnGoBack = () => {
    dispatch(goBack());
  };

  const handleOnClickMatch = () => {
    setShowMatchDialog(true);
  };

  const handleOnMatch = async () => {
    setShowMatchDialog(false);
    dispatch(commonActions.setLoading(true));

    try {
      await addDoc(collection(db, 'matches'), {
        user_id: auth.currentUser.uid,
        profile_id: profileId,
        category_id: matchProfileData.category,
        name: matchName,
        description: matchDescription,
        deadline: matchDeadline,
        status: 'pending',
        timestamp: new Date(),
        type: 1, // direct match
      });

      notifySuccess('Enviamos tu solicitud de match. ¡Te avisaremos si la acepta!');

      setTimeout(() => {
        goToMatches();
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const renderForm = () => {
    return (
      <div className={classes.stepContent}>
        <Title bold leftAligned size="sm">
          Intentemos que {matchProfileData?.company_name} acepte tu match para {category || ''}
        </Title>
        {matchProfileData?.company_name ? (
          <div className={classes.stepMessage}>
            <Alert severity="info">
              Describí con el mayor nivel de detalle lo que querés para que {matchProfileData?.company_name} acepte tu
              match.
            </Alert>
          </div>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            {matchProfileData ? (
              <MatchCard match={matchProfileData} onClickProfile={() => setShowProfileInfo(true)} readOnly />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  label="¿Cuál es tu quiero?"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Describí tu quiero en una palabra o una frase corta"
                  value={matchName}
                  onChange={(e) => setMatchName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  label="Describí tu quiero"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={15}
                  placeholder="Contá (con el mayor nivel de detalle que puedas) qué querés"
                  value={matchDescription}
                  onChange={(e) => setMatchDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  label="¿Tenés una fecha límite?"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  limitMinDate
                  value={matchDeadline}
                  onChange={(e) => setMatchDeadline(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.actionsWrapper}>
          <Button type="outlined" onClick={handleOnGoBack}>
            Cancelar
          </Button>
          <Button type="green" onClick={handleOnClickMatch} disabled={!canSubmitForm}>
            Confirmar
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Page>
      <Header withBorder size="md" showSearchBar={false} />
      <Container>
        <div className={classes.container}>{renderForm()}</div>
      </Container>
      <MatchDialog
        open={showMatchDialog}
        matchName={matchProfileData?.company_name}
        onClose={() => setShowMatchDialog(false)}
        onMatch={handleOnMatch}
      />
      <MatchProfile
        open={showProfileInfo}
        onClose={() => setShowProfileInfo(false)}
        readOnly
        profile={matchProfileData}
        categoryId={searchParams.get('category')}
      />
    </Page>
  );
};

MatchScreen.id = 'com.QuieroMatch.Match';

export default MatchScreen;
