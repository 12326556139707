import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Header, ImageGrid, Page, TextInput, Title } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import makeClasses from './styles';
import { Grid, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { useFirebase, useToasts } from '../../hooks';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { common as commonActions, user as userActions } from '../../actions';
import clsx from 'clsx';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Alert from '@material-ui/lab/Alert';
import { sendEmailVerification } from 'firebase/auth';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';

const departments = [
  { id: 1, name: 'Artigas', locations: ['Artigas', 'Bella Unión', 'Tomás Gomensoro', 'Baltasar Brum'] },
  {
    id: 2,
    name: 'Canelones',
    locations: [
      'Canelones',
      'Las Piedras',
      'Pando',
      'Ciudad de la Costa',
      'La Paz',
      'Progreso',
      'Santa Lucía',
      'Sauce',
      'Tala',
      'Toledo',
      'Atlántida',
      'Empalme Olmos',
    ],
  },
  { id: 3, name: 'Cerro Largo', locations: ['Melo', 'Río Branco', 'Fraile Muerto', 'Aceguá'] },
  {
    id: 4,
    name: 'Colonia',
    locations: ['Colonia del Sacramento', 'Carmelo', 'Nueva Helvecia', 'Rosario', 'Nueva Palmira', 'Juan Lacaze'],
  },
  { id: 5, name: 'Durazno', locations: ['Durazno', 'Sarandí del Yí', 'Villa del Carmen'] },
  { id: 6, name: 'Flores', locations: ['Trinidad', 'Ismael Cortinas'] },
  { id: 7, name: 'Florida', locations: ['Florida', 'Sarandí Grande', '25 de Agosto'] },
  { id: 8, name: 'Lavalleja', locations: ['Minas', 'José Pedro Varela', 'Solís de Mataojo'] },
  {
    id: 9,
    name: 'Maldonado',
    locations: ['Maldonado', 'Punta del Este', 'San Carlos', 'Piriápolis', 'Pan de Azúcar', 'Aiguá'],
  },
  { id: 10, name: 'Montevideo', locations: ['Montevideo'] },
  { id: 11, name: 'Paysandú', locations: ['Paysandú', 'Guichón', 'Quebracho'] },
  { id: 12, name: 'Río Negro', locations: ['Fray Bentos', 'Young', 'San Javier', 'Nuevo Berlín'] },
  { id: 13, name: 'Rivera', locations: ['Rivera', 'Tranqueras', 'Vichadero'] },
  { id: 14, name: 'Rocha', locations: ['Rocha', 'Chuy', 'Castillos', 'Lascano', 'La Paloma'] },
  { id: 15, name: 'Salto', locations: ['Salto', 'Constitución', 'Belén'] },
  {
    id: 16,
    name: 'San José',
    locations: ['San José de Mayo', 'Ciudad del Plata', 'Libertad', 'Ecilda Paullier', 'Rodríguez'],
  },
  { id: 17, name: 'Soriano', locations: ['Mercedes', 'Dolores', 'Cardona', 'José Enrique Rodó', 'Palmitas'] },
  { id: 18, name: 'Tacuarembó', locations: ['Tacuarembó', 'Paso de los Toros', 'San Gregorio de Polanco'] },
  { id: 19, name: 'Treinta y Tres', locations: ['Treinta y Tres', 'Vergara', 'Santa Clara de Olimar'] },
];

const activityTypes = [
  {
    id: 1,
    name: 'Empresa',
  },
  {
    id: 2,
    name: 'Profesional Independiente',
  },
  {
    id: 3,
    name: 'Independiente',
  },
];

const activityModes = [
  {
    id: 1,
    name: 'Remoto',
  },
  {
    id: 2,
    name: 'Presencial',
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index ? children : null}
    </div>
  );
}

const ProfileScreen = () => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { db, auth, storage } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const fileInputRef = useRef();
  const [inputProfileImage, setInputProfileImage] = useState(null);
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputDepartment, setInputDepartment] = useState(null);
  const [inputLocation, setInputLocation] = useState(null);
  const [inputBrief, setInputBrief] = useState('');
  const [inputCompanyName, setInputCompanyName] = useState('');
  const [inputCompanyActivityYears, setInputCompanyActivityYears] = useState('');
  const [inputActivityType, setInputActivityType] = useState('');
  const [inputActivityModes, setInputActivityModes] = useState([]);
  const [inputCategories, setInputCategories] = useState([]);
  const [inputCategoryData, setInputCategoryData] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategoryTab, setSelectedCategoryTab] = useState(0);
  const [emailVerified, setEmailVerified] = useState(false);

  const { userData } = useSelector((state) => ({
    userData: state.user?.data || null,
  }));

  const profileCompleted = userData?.profile_completed;
  const formCompleted =
    userData?.type === 1
      ? inputFirstName?.length > 0 &&
        inputLastName?.length > 0 &&
        inputCompanyName?.length > 0 &&
        inputProfileImage?.length > 0 &&
        inputCategories?.length > 0 &&
        inputActivityType > 0 &&
        inputBrief.length > 0
      : inputFirstName?.length > 0 && inputLastName?.length > 0 && inputProfileImage;
  const canSubmitForm =
    formCompleted &&
    auth?.currentUser &&
    emailVerified &&
    ((profileCompleted && !userData?.in_review) ||
      (!profileCompleted && (userData?.in_review || typeof userData?.in_review === 'undefined')));

  useEffect(() => {
    if (userData) {
      if (userData?.type === 1) {
        setInputCategories(userData.categories);
        setInputCompanyName(userData?.company_name || '');
        setInputCompanyActivityYears(userData?.company_activity_years || '');
        setInputActivityType(userData?.activity_type || '');
        setInputActivityModes(userData?.activity_modes || []);

        const categoryData = {};
        userData.categories.forEach((c) => {
          categoryData[c] = {
            images: userData?.category_data?.[c]?.images || [],
            skills: userData?.category_data?.[c]?.skills || userData?.skills?.map((s) => s.name) || [],
            education: userData?.category_data?.[c]?.education || userData?.education || [],
            experience: userData?.category_data?.[c]?.experience || userData?.experience || [],
          };
        });
        setInputCategoryData(categoryData);

        if (db) {
          getCategories();
        }
      }

      setInputFirstName(userData?.first_name);
      setInputLastName(userData?.last_name);
      setInputProfileImage(userData?.profile_image || null);
      setInputBrief(userData?.brief || '');
      setInputDepartment(departments.find((d) => d.id === userData?.department)?.id || '');
      setInputLocation(userData?.department_location || '');
    }
  }, [userData, db]);

  useEffect(() => {
    let interval = null;

    if (!auth?.currentUser?.emailVerified) {
      interval = setInterval(() => {
        auth.currentUser.reload().then(() => {
          if (auth?.currentUser.emailVerified) {
            setEmailVerified(true);
            clearInterval(interval);
            interval = null;
          }
        });
      }, 5000);
    } else {
      setEmailVerified(auth?.currentUser?.emailVerified || false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [auth?.currentUser]);

  useEffect(() => {
    if (inputCategories.length > 0) {
      let missingCategory = '';
      for (const categoryId of inputCategories) {
        if (!inputCategoryData[categoryId]) {
          missingCategory = categoryId;
          break;
        }
      }

      if (missingCategory) {
        const categoryData = {
          ...inputCategoryData,
          [missingCategory]: {
            images: [],
            skills: [],
            education: [],
            experience: [],
          },
        };

        setInputCategoryData(categoryData);
      }
    } else {
      setInputCategoryData({});
    }
  }, [inputCategories]);

  const getCategories = async () => {
    try {
      dispatch(commonActions.setLoading(true));
      const categories = await getDocs(collection(db, 'categories'));
      setCategories(categories.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(commonActions.setLoading(false));
    }
  };

  const handleFileUploadOnChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        notifyError('La imágen no puede superar los 3MB de tamaño.');
        return;
      }

      // Create a FileReader to read the file
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        setInputProfileImage(event.target.result);
      });

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleOnFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleOnClickBack = () => {
    dispatch(goBack());
  };

  const handleOnResendVerificationLink = async (e) => {
    e.preventDefault();

    try {
      dispatch(commonActions.setLoading(true));
      await sendEmailVerification(auth.currentUser);
      notifySuccess('Link de verificación reenviado correctamente.');
      dispatch(commonActions.setLoading(false));
    } catch (error) {
      notifyError('En este momento no se puede reenviar el email, intenta de nuevo más tarde.');
    }
  };

  const handleOnSave = async () => {
    try {
      dispatch(commonActions.setLoading(true));

      let categoryData = {};
      let hasImagesToUpload = false;
      if (userData?.type === 1) {
        for (const categoryId in inputCategoryData) {
          const images = inputCategoryData[categoryId]?.images?.filter((e) => e)?.map((e) => e.file || e.img) || [];

          const experience =
            inputCategoryData[categoryId]?.experience?.filter(
              (e) =>
                e.company_name?.length > 0 ||
                e.position?.length > 0 ||
                e.start_date?.length > 0 ||
                e.end_date?.length > 0 ||
                e.description?.length > 0
            ) || [];

          const education =
            inputCategoryData[categoryId]?.education?.filter(
              (e) =>
                e.institution_name?.length > 0 ||
                e.degree?.length > 0 ||
                e.start_date?.length > 0 ||
                e.end_date?.length > 0 ||
                e.description?.length > 0
            ) || [];

          categoryData[categoryId] = {
            ...inputCategoryData[categoryId],
            images,
            education,
            experience,
          };

          if (images.filter((i) => typeof i !== 'string').length > 0) {
            hasImagesToUpload = true;
          }
        }
      } else {
        categoryData = null;
      }

      const data = {
        brief: inputBrief || '',
        first_name: inputFirstName,
        last_name: inputLastName,
        categories: userData?.type === 1 ? inputCategories : null,
        department: inputDepartment || null,
        department_location: inputLocation || null,
        category_data: userData?.type === 1 ? categoryData : null,
        activity_modes: userData?.type === 1 ? inputActivityModes : null,
        activity_type: userData?.type === 1 ? inputActivityType : null,
        company_name: userData?.type === 1 ? inputCompanyName : null,
        company_activity_years: userData?.type === 1 ? inputCompanyActivityYears : null,
      };

      if (fileInputRef.current.files[0]) {
        const imageName = userData?.profile_completed
          ? 'profileImages/' + auth.currentUser.uid + '-new'
          : 'profileImages/' + auth.currentUser.uid;
        const storageRef = ref(storage, imageName);

        const uploadedBytes = await uploadBytes(storageRef, fileInputRef.current.files[0]);
        data.profile_image = await getDownloadURL(uploadedBytes.ref);
      }

      if (userData?.type === 1 && hasImagesToUpload) {
        for (const categoryId in categoryData) {
          const images = categoryData[categoryId].images.slice();
          for (let i = 0, l = images.length; i < l; i += 1) {
            if (typeof images[i] !== 'string') {
              const imageName = `categoryImages/${auth.currentUser.uid}-${categoryId}-${i}`;
              const storageRef = ref(storage, imageName);
              const uploadedBytes = await uploadBytes(storageRef, images[i]);
              images[i] = await getDownloadURL(uploadedBytes.ref);
            }
          }
          categoryData[categoryId].images = images;
        }
      }

      if (userData?.profile_completed) {
        await updateDoc(doc(db, 'users', auth.currentUser.uid), data);
        dispatch(userActions.setData({ ...userData, ...data, in_review: true }));

        notifySuccess('Cambios solicitados correctamente.');
      } else {
        await updateDoc(doc(db, 'users', auth.currentUser.uid), { ...data, profile_completed: true });
        dispatch(userActions.setData({ ...userData, ...data, profile_completed: true, in_review: true }));

        notifySuccess('Perfil creado correctamente.');

        setTimeout(() => {
          dispatch(push('/home'));
        }, 500);
      }

      window.scrollTo(0, 0);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    } finally {
      dispatch(commonActions.setLoading(false));
    }
  };

  const getOptionLabel = (type) => (option) => {
    if (type === 'categories' && categories.length > 0) {
      return categories.find((c) => c.id === option)?.name || '';
    }
    if (type === 'activityTypes') {
      return activityTypes.find((c) => c.id === option)?.name || '';
    }
    if (type === 'activityModes') {
      return activityModes.find((c) => c.id === option)?.name || '';
    }
    if (type === 'department') {
      return departments.find((c) => c.id === option)?.name || '';
    }
    if (type === 'location') {
      const department = departments.find((c) => c.id === inputDepartment) || null;
      return department?.locations.find((l) => l === option) || '';
    }
    return '';
  };

  const handleOnClickAddExperience = (categoryId) => () => {
    const experience = {
      company_name: '',
      position: '',
      start_date: '',
      end_date: '',
      description: '',
    };
    setInputCategoryData({
      ...inputCategoryData,
      [categoryId]: {
        ...inputCategoryData[categoryId],
        experience: [...(inputCategoryData[categoryId].experience || []), experience],
      },
    });
  };

  const handleOnClickAddEducation = (categoryId) => () => {
    const education = {
      institution_name: '',
      degree: '',
      start_date: '',
      end_date: '',
      description: '',
    };
    setInputCategoryData({
      ...inputCategoryData,
      [categoryId]: {
        ...inputCategoryData[categoryId],
        education: [...(inputCategoryData[categoryId].education || []), education],
      },
    });
  };

  const handleOnClickDeleteEducation = (categoryId, index) => () => {
    const newEducation = [...inputCategoryData[categoryId].education];
    newEducation.splice(index, 1);
    setInputCategoryData({
      ...inputCategoryData,
      [categoryId]: {
        ...inputCategoryData[categoryId],
        education: newEducation,
      },
    });
  };

  const handleOnClickDeleteExperience = (categoryId, index) => () => {
    const newExperience = [...inputCategoryData[categoryId].experience];
    newExperience.splice(index, 1);
    setInputCategoryData({
      ...inputCategoryData,
      [categoryId]: {
        ...inputCategoryData[categoryId],
        experience: newExperience,
      },
    });
  };

  const handleOnChangeCategoryTab = (e, newValue) => {
    setSelectedCategoryTab(newValue);
  };

  const renderProfileForm = () => {
    if (!userData || !auth?.currentUser) {
      return null;
    }

    if (userData?.type === 0) {
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} className={classes.imageContainer}>
              <div className={clsx(classes.image, !inputProfileImage ? classes.imageWithBackground : null)}>
                {!inputProfileImage ? (
                  <div>
                    {`${userData?.first_name.substring(0, 1)}${userData?.last_name.substring(0, 1)}`.toUpperCase()}
                  </div>
                ) : (
                  <img src={inputProfileImage} />
                )}
              </div>
              <div className={classes.imageUploadWrapper}>
                <Button type="outlined" fullWidth onClick={handleOnFileUpload}>
                  Cambiar imagen
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => handleFileUploadOnChange(e)}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextInput
                    value={inputFirstName}
                    label="Nombre"
                    onChange={(e) => setInputFirstName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextInput
                    value={inputLastName}
                    label="Apellido"
                    onChange={(e) => setInputLastName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextInput value={userData?.email} disabled label="Email" required />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.profileSectionSeparator} />
          <Title size="sm" leftAligned className={classes.formSectionWrapper}>
            Datos de ubicación
          </Title>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                options={departments.map((option) => option.id)}
                getOptionLabel={getOptionLabel('department')}
                onChange={(e, value) => setInputDepartment(value)}
                renderInput={(params) => <TextInput {...params} label="Departamento" variant="outlined" />}
                defaultValue={inputDepartment}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                options={inputDepartment ? departments.find((d) => d.id === inputDepartment).locations : []}
                getOptionLabel={getOptionLabel('location')}
                onChange={(e, value) => setInputLocation(value)}
                renderInput={(params) => <TextInput {...params} label="Localidad" variant="outlined" />}
                defaultValue={inputLocation}
                disabled={!inputDepartment}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button type="green" size="md" onClick={handleOnSave} fullWidth disabled={!canSubmitForm}>
                {userData?.profile_completed ? 'Guardar' : 'Guardar y continuar'}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Title size="sm" leftAligned className={classes.formSectionWrapper}>
          Datos personales
        </Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} className={classes.imageContainer}>
            <div className={clsx(classes.image, !inputProfileImage ? classes.imageWithBackground : null)}>
              {!inputProfileImage ? (
                <div>
                  {`${userData?.first_name.substring(0, 1)}${userData?.last_name.substring(0, 1)}`.toUpperCase()}
                </div>
              ) : (
                <img src={inputProfileImage} />
              )}
            </div>
            <div className={classes.imageUploadWrapper}>
              <Button type="outlined" fullWidth onClick={handleOnFileUpload}>
                Cambiar imagen
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileUploadOnChange(e)}
                accept="image/*"
                style={{ display: 'none' }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  value={inputFirstName}
                  label="Nombre"
                  onChange={(e) => setInputFirstName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  value={inputLastName}
                  label="Apellido"
                  onChange={(e) => setInputLastName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput value={userData?.email} disabled label={'Email de trabajo'} required />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  options={departments.map((option) => option.id)}
                  getOptionLabel={getOptionLabel('department')}
                  onChange={(e, value) => setInputDepartment(value)}
                  renderInput={(params) => <TextInput {...params} label="Departamento" variant="outlined" />}
                  defaultValue={inputDepartment}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  options={inputDepartment ? departments.find((d) => d.id === inputDepartment).locations : []}
                  getOptionLabel={getOptionLabel('location')}
                  onChange={(e, value) => setInputLocation(value)}
                  renderInput={(params) => <TextInput {...params} label="Localidad" variant="outlined" />}
                  defaultValue={inputLocation}
                  disabled={!inputDepartment}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.profileSectionSeparator} />
        <Title size="sm" leftAligned>
          Presentación
        </Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput
              label="¿Por qué eres un buen match?"
              multiline
              rows={3}
              value={inputBrief}
              required
              onChange={(e) => setInputBrief(e.target.value)}
              helperText="Describí porqué eres un buen match"
            />
          </Grid>
        </Grid>
        <div className={classes.profileSectionSeparator} />
        <Title size="sm" leftAligned className={classes.formSectionWrapper}>
          Datos de actividad
        </Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              options={activityTypes.map((option) => option.id)}
              getOptionLabel={getOptionLabel('activityTypes')}
              onChange={(e, value) => setInputActivityType(value)}
              renderInput={(params) => <TextInput {...params} label="Tipo de actividad" variant="outlined" required />}
              defaultValue={inputActivityType}
            />
          </Grid>
          {inputActivityType === 1 ? (
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="Nombre de empresa"
                onChange={(e) => setInputCompanyName(e.target.value)}
                value={inputCompanyName}
                required
              />
            </Grid>
          ) : null}
          {inputActivityType === 2 ? (
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="Nombre de empresa o Emprendimiento"
                onChange={(e) => setInputCompanyName(e.target.value)}
                value={inputCompanyName}
                required
              />
            </Grid>
          ) : null}
          {inputActivityType === 3 ? (
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="Nombre de tu servicio o actividad"
                onChange={(e) => setInputCompanyName(e.target.value)}
                value={inputCompanyName}
                required
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              options={activityModes.map((option) => option.id)}
              getOptionLabel={getOptionLabel('activityModes')}
              onChange={(e, value) => setInputActivityModes(value)}
              renderInput={(params) => <TextInput {...params} label="Modalidad de trabajo" variant="outlined" />}
              defaultValue={inputActivityModes}
              multiple
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              label={inputActivityType === 1 ? 'Años en actividad' : 'Años de experiencia'}
              onChange={(e) => setInputCompanyActivityYears(e.target.value)}
              value={inputCompanyActivityYears}
            />
          </Grid>
        </Grid>
        <div className={classes.profileSectionSeparator} />
        <Title size="sm" leftAligned>
          Categorías
        </Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              options={categories.map((option) => option.id)}
              getOptionLabel={getOptionLabel('categories')}
              onChange={(e, value) => setInputCategories(value)}
              renderInput={(params) => <TextInput {...params} label="Área de trabajo" variant="outlined" required />}
              defaultValue={inputCategories}
              multiple
            />
          </Grid>
        </Grid>
        <div className={classes.tabsContainer}>
          <Tabs
            orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            variant="scrollable"
            value={selectedCategoryTab}
            onChange={handleOnChangeCategoryTab}
            className={classes.tabs}
            indicatorColor="primary"
          >
            {categories
              .filter((c) => inputCategories.includes(c.id))
              ?.map((c, index) => (
                <Tab key={index} label={c.name} />
              ))}
          </Tabs>
          {categories
            .filter((c) => inputCategories.includes(c.id) && inputCategoryData[c.id])
            ?.map((c, index) => (
              <TabPanel key={index} value={selectedCategoryTab} index={index}>
                {c.images ? (
                  <React.Fragment>
                    <Title size="sm" leftAligned>
                      Mostrá tu top 5
                    </Title>
                    <div className={classes.messageContainer}>
                      Mostrá lo que consideres es el top 5 de tu trabajo para esta categoría.
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <ImageGrid
                          images={inputCategoryData[c.id].images || []}
                          onChange={(images) =>
                            setInputCategoryData({
                              ...inputCategoryData,
                              [c.id]: {
                                ...inputCategoryData[c.id],
                                images,
                              },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
                <div className={classes.profileSectionSeparator} />
                <Alert severity="info" style={{ marginTop: 30, marginBottom: 0 }}>
                  Agregá las habilidades, rubros o actividades en los que te desempeñás, esto ayudará a que te
                  encuentren más fácilmente y tengas más chances de matchear.
                </Alert>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Title size="sm" leftAligned>
                      Habilidades / Rubros / Actividades
                    </Title>
                    <Autocomplete
                      options={inputCategoryData[c.id]?.skills || []}
                      onChange={(e, value) =>
                        setInputCategoryData({
                          ...inputCategoryData,
                          [c.id]: {
                            ...inputCategoryData[c.id],
                            skills: value?.map((s) => _.capitalize(s).trim()) || [],
                          },
                        })
                      }
                      renderInput={(params) => (
                        <TextInput
                          {...params}
                          variant="outlined"
                          required
                          placeholder="Ej: Diseño, Autocad, Contabilidad empresarial, Constancias de ingresos, Clases liceo etc."
                        />
                      )}
                      defaultValue={inputCategoryData[c.id]?.skills || []}
                      multiple
                      freeSolo
                    />
                  </Grid>
                </Grid>
                <div className={classes.profileSectionSeparator} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.titleContainer}>
                      <Title size="sm" leftAligned>
                        Experiencia
                      </Title>
                      <Button type="outlined" icon onClick={handleOnClickAddExperience(c.id)}>
                        <AddIcon />
                      </Button>
                    </div>
                    <Grid container spacing={2}>
                      {inputCategoryData[c.id]?.experience?.map((experience, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                              label="Empresa"
                              onChange={(e) => {
                                const newExperience = [...inputCategoryData[c.id].experience];
                                newExperience[index].company_name = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], experience: newExperience },
                                });
                              }}
                              value={inputCategoryData[c.id].experience.company_name}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                              label="Puesto"
                              onChange={(e) => {
                                const newExperience = [...inputCategoryData[c.id].experience];
                                newExperience[index].position = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], experience: newExperience },
                                });
                              }}
                              value={inputCategoryData[c.id].experience.position}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                              label="Fecha de inicio"
                              type="date"
                              onChange={(e) => {
                                const newExperience = [...inputCategoryData[c.id].experience];
                                newExperience[index].start_date = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], experience: newExperience },
                                });
                              }}
                              value={inputCategoryData[c.id].experience.start_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                              label="Fecha de fin"
                              type="date"
                              onChange={(e) => {
                                const newExperience = [...inputCategoryData[c.id].experience];
                                newExperience[index].end_date = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], experience: newExperience },
                                });
                              }}
                              value={inputCategoryData[c.id].experience.end_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                              label="Descripción"
                              multiline
                              rows={3}
                              onChange={(e) => {
                                const newExperience = [...inputCategoryData[c.id].experience];
                                newExperience[index].description = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], experience: newExperience },
                                });
                              }}
                              value={inputCategoryData[c.id].experience.description}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Button
                              type="outlined"
                              onClick={handleOnClickDeleteExperience(c.id, index)}
                              variant="danger"
                            >
                              Eliminar
                            </Button>
                          </Grid>
                          {index < inputCategoryData[c.id].experience?.length - 1 ? (
                            <div className={classes.itemSeparator} />
                          ) : null}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.titleContainer}>
                      <Title size="sm" leftAligned>
                        Educación
                      </Title>
                      <Button type="outlined" icon onClick={handleOnClickAddEducation(c.id)}>
                        <AddIcon />
                      </Button>
                    </div>
                    <Grid container spacing={2}>
                      {inputCategoryData[c.id]?.education?.map((education, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                              label="Institución"
                              onChange={(e) => {
                                const newEducation = [...inputCategoryData[c.id].education];
                                newEducation[index].institution_name = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], education: newEducation },
                                });
                              }}
                              value={inputCategoryData[c.id].education.institution_name}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                              label="Título"
                              onChange={(e) => {
                                const newEducation = [...inputCategoryData[c.id].education];
                                newEducation[index].degree = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], education: newEducation },
                                });
                              }}
                              value={inputCategoryData[c.id].education.degree}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                              label="Fecha de inicio"
                              type="date"
                              onChange={(e) => {
                                const newEducation = [...inputCategoryData[c.id].education];
                                newEducation[index].start_date = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], education: newEducation },
                                });
                              }}
                              value={inputCategoryData[c.id].education.start_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                              label="Fecha de fin"
                              type="date"
                              onChange={(e) => {
                                const newEducation = [...inputCategoryData[c.id].education];
                                newEducation[index].end_date = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], education: newEducation },
                                });
                              }}
                              value={inputCategoryData[c.id].education.end_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                              label="Descripción"
                              multiline
                              rows={3}
                              onChange={(e) => {
                                const newEducation = [...inputCategoryData[c.id].education];
                                newEducation[index].description = e.target.value;
                                setInputCategoryData({
                                  ...inputCategoryData,
                                  [c.id]: { ...inputCategoryData[c.id], education: newEducation },
                                });
                              }}
                              value={inputCategoryData[c.id].education.description}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Button
                              type="outlined"
                              onClick={handleOnClickDeleteEducation(c.id, index)}
                              variant="danger"
                            >
                              Eliminar
                            </Button>
                          </Grid>
                          {index < inputCategoryData[c.id].education?.length - 1 ? (
                            <div className={classes.itemSeparator} />
                          ) : null}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            ))}
        </div>
        <div className={classes.profileSectionSeparator} />
        <Grid container spacing={2} className={classes.formSectionWrapper}>
          <Grid item xs={12} sm={12} md={12}>
            <Button type="green" size="md" onClick={handleOnSave} fullWidth disabled={!canSubmitForm}>
              {userData?.profile_completed ? 'Guardar' : 'Guardar y continuar'}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Page>
      <Header size="md" withBorder showSearchBar={false} showProfile={false} />
      <Container>
        <div className={classes.container}>
          {auth?.currentUser ? (
            <React.Fragment>
              {!emailVerified ? (
                <Alert severity="warning" className={classes.activateAccountContainer}>
                  Te hemos enviado un email para verificar tu correo electrónico y activar tu cuenta, no podrás realizar
                  modificaciones a tu perfil mientras que no hayas activado tu cuenta. Si no recibiste el email de
                  verificaición podés solicitar que te lo reenviemos.{' '}
                  <a href="#" onClick={handleOnResendVerificationLink}>
                    Reenviar link de verificación
                  </a>
                </Alert>
              ) : null}
              {userData?.profile_completed && userData?.in_review ? (
                <Alert severity="warning" className={classes.activateAccountContainer}>
                  Los cambios realizados a tu perfil se encuentran en revisión por el equipo de Quiero Match, te
                  notificaremos cuando queden aprobados.
                </Alert>
              ) : null}
            </React.Fragment>
          ) : null}
          <div className={classes.titleContainer}>
            <Title leftAligned>Perfil</Title>
            {userData?.profile_completed ? (
              <Button type="outlined" onClick={handleOnClickBack}>
                Volver
              </Button>
            ) : null}
          </div>
          {userData?.type === 1 ? (
            <Alert severity="info" style={{ marginBottom: 20 }}>
              Recordá que cuanto más detallada la información en tu perfil sobre lo que hacés, tu actividad y
              habilidades, mayores serán las chances de matchear.
            </Alert>
          ) : null}
          {!profileCompleted ? (
            <div className={classes.mainMessageContainer}>
              Completá tu registro, subí una imagen de perfil reciente y revisá tu información a continuación.
            </div>
          ) : null}
          <div className={classes.formContainer}>{renderProfileForm()}</div>
        </div>
      </Container>
    </Page>
  );
};

ProfileScreen.id = 'com.QuieroMatch.Profile';

export default ProfileScreen;
