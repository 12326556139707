import React from 'react';
import { useSelector } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import makeClasses from './styles';

function Page({ children }) {
  const { loading } = useSelector((state) => ({ loading: state.common.loading || state.user.loading }));
  const classes = makeClasses();

  return (
    <div className={classes.root}>
      {children}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Page;
