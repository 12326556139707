import React, { useRef, useState } from 'react';
import makeClasses from './styles';
import StarIcon from '@material-ui/icons/Star';
import Button from '../Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Dialog, DialogActions, DialogContent, DialogTitle, Popover } from '@material-ui/core';
import { useSelector } from 'react-redux';

const MatchCard = ({ match, onClickProfile, onClickMatch, readOnly = false, showBudget = false, budget = null }) => {
  const classes = makeClasses();
  const popoverRef = useRef();

  const [showPopover, setShowpopover] = useState(false);
  const [showBudgetDialog, setShowBudgetDialog] = useState(false);
  const isLoggedIn = useSelector((state) => !!state.user.data);

  const handlePopoverClose = () => {
    setShowpopover(false);
  };

  const togglePopover = () => {
    setShowpopover(!showPopover);
  };

  const toggleBudgetDialog = () => {
    setShowBudgetDialog(!showBudgetDialog);
  };

  const renderName = () => {
    //if (isLoggedIn) {
    if (match.type === 0) {
      return `${match.first_name} ${match.last_name}`;
    }
    return match.company_name;
    // }
    // return match.username;
  };

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        {/* {isLoggedIn ? <img src={match.full_image} /> : <div style={{ background: match.image }} />} */}
        <img src={match.full_image} />
      </div>
      <div className={classes.name}>
        <div>{renderName()}</div>
        {/* {!isLoggedIn ? (
          <div className={classes.nameInfo} onClick={togglePopover} ref={popoverRef}>
            <InfoOutlinedIcon fontSize="small" />
            <Popover
              className={classes.popover}
              classes={{
                paper: classes.popoverContent,
              }}
              open={showPopover}
              anchorEl={popoverRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              Este nombre de usuario se generó de forma aleatoria para proteger la privacidad del proveedor.
            </Popover>
          </div>
        ) : null} */}
      </div>
      {match.type_name ? <div className={classes.area}>{match.type_name}</div> : null}
      {match?.match?.requires_budget ? <div className={classes.area}>Requiere presupuesto</div> : null}
      <div className={classes.rate}>
        {match.rate > 0 ? <StarIcon /> : null}
        <span>{!match.rate ? 'Sin calificaciones' : `${match.rate}/5`}</span>
      </div>
      {match.rate > 0 ? (
        <div className={classes.wins}>
          {match.matches === 1 ? `(${match.matches} match)` : `(${match.matches} matches)`}
        </div>
      ) : null}
      <div className={classes.actions}>
        {onClickProfile ? (
          <Button type="outlined" onClick={onClickProfile}>
            {match.type === 0 ? <span>Ver quiero</span> : <span>Ver perfil</span>}
          </Button>
        ) : null}
        {match.type === 1 && showBudget ? (
          <Button type="outlined" onClick={toggleBudgetDialog}>
            Ver presupuesto
          </Button>
        ) : null}
        {/* {!readOnly && match.type === 1 && onClickMatch ? (
          <Button type="green" onClick={onClickMatch}>
            Matchear
          </Button>
        ) : null} */}
      </div>
      <Dialog open={showBudgetDialog} onClose={toggleBudgetDialog} fullWidth>
        <DialogTitle>Presupuesto</DialogTitle>
        <DialogContent dividers>{budget}</DialogContent>
        <DialogActions>
          <Button type="outlined" onClick={toggleBudgetDialog}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MatchCard;
