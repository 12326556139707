import React from 'react';
import clsx from 'clsx';
import makeClasses from './styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '../Button';

const MatchDialo = ({ open, matchName, direct = false, onMatch, onClose }) => {
  const classes = makeClasses();

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Quiero Match
      </DialogTitle>
      <DialogContent dividers>
        {direct ? (
          <div>
            Al confirmar estarás haciendo match, te pondremos en contacto con {matchName} y podrán concretar tu quiero.
          </div>
        ) : (
          <div>
            Le enviaremos tu solicitud de match a {matchName}, si lo acepta te pondremos en contacto y podrán concretar
            tu quiero.
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button type="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button type="green" onClick={onMatch}>
          {direct ? <span>¡Matchear!</span> : <span>¡Enviá tu match!</span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchDialo;
