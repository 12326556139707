import React from 'react';
import makeClasses from './styles';

const Slogan = () => {
  const classes = makeClasses();

  return <div className={classes.container}>Encontrá tu match.</div>;
};

export default Slogan;
