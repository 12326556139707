import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 90px - 55px)',
    padding: '40px 0 60px',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
  },
  stepperContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '6px solid #00796B',
      background: '#FFF3E0',
      color: '#FF6F00',
      width: 60,
      height: 60,
      borderRadius: 60,
      position: 'relative',
      boxSizing: 'border-box',

      '& > span': {
        fontSize: 72,
        fontWeight: '500',
        position: 'absolute',
        bottom: -47,
        right: -10,
      },
    },
  },
  stepDisabled: {
    opacity: 0.5,
  },
  stepContent: {
    marginTop: 0,
  },
  stepMessage: {
    margin: '20px 0 40px',
    fontWeight: '300',
  },
  infoContainer: {
    marginTop: 30,
  },
  infoItem: {
    margin: '20px 0',
    color: '#555',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  infoItemLabel: {
    fontWeight: '500',
    fontSize: 16,
  },
  infoItemText: {
    fontWeight: '300',
    fontSize: 16,
  },
  actionsWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    paddingTop: 15,
  },
  profileSectionContentWithPills: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  profileSectionInfoItemPill: {
    background: '#f5f5f5',
    padding: '5px 10px',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: 5,
    fontSize: 16,
    fontWeight: '300',
    color: '#333',
    marginRight: 7,

    '&:last-child': {
      marginRight: 0,
    },
  },
  titleWithButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default makeClasses;
