import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 90px - 39px)',
    width: '100%',
    margin: '0 auto',
    padding: '40px 0 60px',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '40px 0',
    },
  },
  tabsContainer: {
    display: 'flex',
    marginTop: 40,

    '& [role="tabpanel"]': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',

      '&[hidden]': {
        display: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tabs: {
    borderRight: `1px solid rgba(0,0,0,0.1)`,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
  },
  tabPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    boxSizing: 'border-box',
  },
  titleWithButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  titleButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > button': {
      marginRight: 5,

      '&:last-child': {
        marginRight: 0,
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      marginTop: 15,

      '& > button': {
        width: '100%',
        marginTop: 5,
        marginRight: 0,

        '&:last-child': {
          marginTop: 0,
        },
      },
    },
  },
}));

export default makeClasses;
