import React from 'react';
import clsx from 'clsx';
import makeClasses from './styles';

const Button = ({ children, type, disabled, onClick, fullWidth, size = 'sm', icon = false, variant = 'normal' }) => {
  const classes = makeClasses();
  const typeClass =
    type === 'green'
      ? classes.buttonGreen
      : type === 'yellow'
      ? classes.buttonYellow
      : type === 'orange'
      ? classes.buttonOrange
      : type === 'outlined'
      ? classes.buttonOutlined
      : null;

  return (
    <button
      className={clsx(
        classes.button,
        typeClass,
        fullWidth ? classes.buttonWithFullWidth : null,
        size === 'md' ? classes.buttonMd : null,
        size === 'xs' ? classes.buttonXs : null,
        icon ? classes.buttonWithIcon : null,
        variant === 'danger' ? classes.buttonVariantDanger : null,
        variant === 'orange' ? classes.buttonVariantOrange : null
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
