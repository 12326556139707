import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 10,
    display: 'flex',
    padding: 20,
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 100,
    overflow: 'hidden',
    border: '1px solid rgba(0,0,0,0.1)',

    '& > img': {
      width: 100,
      height: 100,
      objectFit: 'cover',
    },
    '& > div': {
      width: 100,
      height: 100,
    },
  },
  name: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#333',
    margin: '10px 0 0',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameInfo: {
    position: 'absolute',
    right: -23,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#555',

    '& > svg': {
      color: '#FF6F00',
    },
  },
  area: {
    fontSize: 14,
    fontWeight: '300',
    marginBottom: 10,
  },
  rate: {
    display: 'flex',
    fontSize: 14,
    color: '#555',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '300',

    '& > svg': {
      marginRight: 5,
      color: '#FF6F00',
    },
  },
  wins: {
    fontSize: 14,
    color: '#555',
    fontWeight: '100',
  },
  actions: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',

    '& > button': {
      marginBottom: 5,

      '&:last-child': { marginBottom: 0 },
    },
  },
  popover: {},
  popoverContent: {
    fontSize: 14,
    color: '#555',
    padding: '10px 15px',
  },
}));

export default makeClasses;
