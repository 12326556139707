import React, { useState } from 'react';
import { AppLogo, Button, Header, Page, TextInput } from '../../components';
import makeClasses from './styles';
import { Grid, Hidden, IconButton } from '@material-ui/core';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { user as userActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { push } from 'connected-react-router';

const LoginScreen = () => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { auth, db } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();

  const [inputEmail, setInputEmail] = useState('');
  const [inputEmailError, setInputEmailError] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [inputPasswordError, setInputPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { loading } = useSelector((state) => ({ loading: state.common.loading || state.user.loading }));

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+\[\]{};:,.<>/?|~\\])[A-Za-z\d!@#$%^&*()-_=+\[\]{};:,.<>/?|~\\]{8,}$/;
  const canSubmitForm = !loading && inputEmail?.length > 0 && inputPassword?.length > 0 && !inputPasswordError;

  const goToRegister = () => {
    dispatch(push('/register'));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!inputEmail) {
      setInputEmailError(true);
      return;
    }

    setInputEmailError(false);

    dispatch(userActions.setLoading(true));
    try {
      await sendPasswordResetEmail(auth, inputEmail);
      notifySuccess('Se ha enviado un email para restablecer tu contraseña.');
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    } finally {
      dispatch(userActions.setLoading(false));
    }
  };

  const submit = async () => {
    dispatch(userActions.setLoading(true));
    try {
      const userCredential = await signInWithEmailAndPassword(auth, inputEmail, inputPassword);
      const user = userCredential.user;
      const userDataDoc = await getDoc(doc(db, 'users', user.uid));

      const idToken = await user.getIdToken(true);
      const userData = userDataDoc.data();

      dispatch(userActions.setToken(idToken));
      dispatch(userActions.setData(userData));

      notifySuccess(`Bienveido/a ${userData.first_name}!`);

      setTimeout(() => {
        if (!userData?.profile_completed) {
          dispatch(push('/profile'));
        } else {
          dispatch(push('/home'));
        }
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    } finally {
      dispatch(userActions.setLoading(false));
    }
  };

  const renderLoginForm = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput label="Email" onChange={(e) => setInputEmail(e.target.value)} error={inputEmailError} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput
              label="Contraseña"
              error={inputPasswordError}
              onChange={(e) => {
                const password = e.target.value;
                if (passwordRegex.test(password)) {
                  // Password is valid
                  setInputPassword(password);
                  setInputPasswordError(false);
                } else {
                  // Password is invalid
                  setInputPasswordError(true);
                }
              }}
              password={!showPassword}
              helperText={
                inputPasswordError
                  ? 'Debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo.'
                  : null
              }
              endAdornment={
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
          </Grid>
        </Grid>
        <div className={classes.loginFormActions}>
          <Button type="green" onClick={submit} fullWidth disabled={!canSubmitForm} size="md">
            Ingresar
          </Button>
        </div>
        <div className={classes.loginFormRegisterCta}>
          <span>¿Olvidaste tu contraseña?</span>
          <a href="#" onClick={handleForgotPassword}>
            Recuperar contraseña
          </a>
        </div>
        <div className={classes.loginFormRegisterLogin}>
          <span>¿No tenés una cuenta?</span>
          <Button type="outlined" onClick={goToRegister} fullWidth size="md">
            Registrate
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Page>
      <Header showSearchBar={false} withBorder />
      <div className={classes.container}>
        <div className={classes.title}>
          <span>Ingresá a tu cuenta</span>
        </div>
        <div className={classes.registerContainer}>{renderLoginForm()}</div>
      </div>
    </Page>
  );
};

LoginScreen.id = 'com.QuieroMatch.Login';

export default LoginScreen;
