import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    background: '#FF6F00',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 136,
    color: '#fff',
    fontSize: 22,
    fontWeight: '300',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
}));

export default makeClasses;
