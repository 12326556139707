import React from 'react';
import makeClasses from './styles';
import moment from 'moment';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '../Button';
import StarIcon from '@material-ui/icons/Star';

const MatchItem = ({ match, onClick, readOnly = false, askForRate = false }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.data);
  const hasRate = userData?.type === 0 ? match?.user_rate > 0 : match?.provider_rate > 0;

  const truncateDescription = (description) => {
    if (description.length > 200) {
      return description.substring(0, 200) + '...';
    } else {
      return description;
    }
  };

  const handleOnClick = () => {
    if (!readOnly) {
      onClick();
    }
  };

  const handleOnClickRate = () => {
    dispatch(push('/rate-match/' + match.id));
  };

  return (
    <div
      className={clsx(
        classes.container,
        match.type === 0 ? classes.contestMatch : classes.directMatch,
        readOnly ? classes.readOnly : null
      )}
      onClick={handleOnClick}
    >
      <div className={classes.itemName}>
        <div className={classes.name}>
          <span>Quiero</span>
          {match.name}
        </div>
        {hasRate ? (
          <div className={classes.rate}>
            <StarIcon className={classes.rateIcon} />
            {match.user_rate || match.provider_rate}/5
          </div>
        ) : null}
      </div>
      {userData?.type === 0 && !match.profile_id ? (
        <div className={classes.itemMatches}>
          {match.matches?.length === 0 ? <span>Sin candidatos</span> : <span>Candidatos: {match.matches?.length}</span>}
        </div>
      ) : null}
      <div className={classes.itemDescription}>{truncateDescription(match.description)}</div>
      <div className={classes.itemTime}>
        {match.status === 'pending' && match.deadline ? (
          <div>
            Fecha límite <span>{moment(match.deadline).format('DD, MMM YYYY')}</span>
          </div>
        ) : (
          <div />
        )}
        <div>
          Creado <span>{moment(match.timestamp.toDate()).format('DD, MMM YYYY')}</span>
        </div>
      </div>
      {askForRate ? (
        <div className={classes.rateButtonContainer}>
          <Button type="green" onClick={handleOnClickRate}>
            Calificá tu experiencia
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default MatchItem;
