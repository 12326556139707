import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  imageWrapper: {
    width: 100,
    height: 100,
    borderRadius: 7,
    borderStyle: 'dashed',
    border: '2px solid rgba(0,0,0,0.2)',
    color: '#999',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: 7,
    },

    '& > div': {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 7,
    },
  },
  imagePlaceholder: {
    width: '100%',
    height: '100%',
    transition: 'opacity 0.3s',

    '&:hover': {
      opacity: 0.7,
    },
  },
  imageWrapperWithImage: {
    borderWidth: 1,
    borderStyle: 'solid',
  },
  removeButton: {
    position: 'absolute',
    top: -14,
    right: -10,
    color: 'red',
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    border: '1px solid #555',
    transition: 'transform 0.3s',

    '&:hover': {
      transform: 'translateY(-2px)',
    },
  },
}));

export default makeClasses;
