import { toast } from 'react-hot-toast';

const useToast = () => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notifyInfo = (message) => toast(message);

  return { notifySuccess, notifyError, notifyInfo };
};

export default useToast;
