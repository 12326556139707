import React, { useEffect, useState } from 'react';
import { Button, Container, EmptyState, Footer, Header, MatchItem, Page, Title } from '../../components';
import makeClasses from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { useFirebase, useToasts } from '../../hooks';
import { common as commonActions } from '../../actions';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useMediaQuery } from '@material-ui/core';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index ? children : null}
    </div>
  );
}

const HomeScreen = () => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { db, user } = useFirebase();
  const { notifyError } = useToasts();
  const tab = new URLSearchParams(window.location.search).get('tab');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const userData = useSelector((state) => state.user.data);

  const [selectedTab, setSelectedTab] = useState(tab ? parseInt(tab) : 0);
  const [pendingMatches, setPendingMatches] = useState([]);
  const [approvedMatches, setApprovedMatches] = useState([]);
  const [activeMatches, setActiveMatches] = useState([]);
  const [cancelledMatches, setCancelledMatches] = useState([]);
  const [rejectedMatches, setRejectedMatches] = useState([]);
  const [completedMatches, setCompletedMatches] = useState([]);

  useEffect(() => {
    if (user) {
      getMatches();
    }
  }, [user]);

  const getMatches = async () => {
    dispatch(commonActions.setLoading(true));

    try {
      let matchesData = [];

      if (userData?.type === 0) {
        const docRef = await getDocs(query(collection(db, 'matches'), where('user_id', '==', user.uid)));
        matchesData = docRef.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      } else {
        const matchesRef = await getDocs(
          query(collection(db, 'matches'), where('matches', 'array-contains', user.uid))
        );
        const profileRef = await getDocs(query(collection(db, 'matches'), where('profile_id', '==', user.uid)));
        matchesData = [
          ...matchesRef.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((m) => !m.profile_id),
          ...profileRef.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];
      }

      if (matchesData.length > 0) {
        setPendingMatches(
          _.orderBy(
            matchesData.filter((m) => m.status === 'pending'),
            ['deadline'],
            ['asc']
          )
        );
        setActiveMatches(
          _.orderBy(
            matchesData.filter((m) => m.status === 'active'),
            ['deadline'],
            ['asc']
          )
        );
        setApprovedMatches(
          _.orderBy(
            matchesData.filter((m) => m.status === 'approved'),
            ['deadline'],
            ['asc']
          )
        );
        setCancelledMatches(
          _.orderBy(
            matchesData.filter((m) => m.status === 'cancelled'),
            ['timestamp'],
            ['desc']
          )
        );
        setRejectedMatches(
          _.orderBy(
            matchesData.filter((m) => m.status === 'rejected'),
            ['timestamp'],
            ['desc']
          )
        );
        setCompletedMatches(
          _.orderBy(
            matchesData.filter((m) => m.status === 'completed'),
            ['timestamp'],
            ['desc']
          )
        );
      }
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const handleOnChangeTab = (e, newValue) => {
    setSelectedTab(newValue);
    dispatch(replace('/home?tab=' + newValue));
  };

  const goToSearch = (category) => () => {
    let query = '';
    if (Array.isArray(category)) {
      query = category.map((q) => `q=${q}`).join('&');
    } else {
      query = `q=${query}`;
    }
    dispatch(push('/search?' + query));
  };

  const goToMatch = (matchId) => () => {
    if (!matchId) {
      dispatch(push('/match-by-category/new'));
      return;
    }
    if (userData?.type === 0) {
      dispatch(push('/match/' + matchId + '/details'));
    } else {
      dispatch(push('/match-provider/' + matchId + '/details'));
    }
  };

  return (
    <Page>
      <Header withBorder showSearchBar={false} />
      <Container>
        <div className={classes.container}>
          {userData?.profile_completed && userData?.in_review ? (
            <Alert severity="warning">
              Los cambios realizados a tu perfil se encuentran en revisión por el equipo de Quiero Match, te
              notificaremos cuando queden aprobados.
            </Alert>
          ) : null}
          <Title bold leftAligned>
            <div className={classes.titleWithButton}>
              {userData?.type === 1 ? <div>Tus matches</div> : <div>Tus quieros</div>}
              {userData?.type === 1 ? (
                <div>
                  <Button type="green" onClick={goToSearch(userData.categories)}>
                    Ver quieros disponibles
                  </Button>
                </div>
              ) : (
                <div className={classes.titleButtonsWrapper}>
                  <Button type="outlined" onClick={goToSearch()}>
                    Buscar match
                  </Button>
                  <Button type="green" onClick={goToMatch()}>
                    Nuevo quiero
                  </Button>
                </div>
              )}
            </div>
          </Title>
          {approvedMatches?.length > 0 && userData?.type === 0 ? (
            <Alert severity="info">
              Es importante que <strong>completes</strong> tus matches para que puedas calificar el servicio de los
              proveedores y de esa forma ayudar a que mas personas puedan encontrar su quiero.
            </Alert>
          ) : null}
          <div className={classes.tabsContainer}>
            <Tabs
              orientation={isSmallScreen ? 'horizontal' : 'vertical'}
              variant="scrollable"
              value={selectedTab}
              onChange={handleOnChangeTab}
              className={classes.tabs}
              indicatorColor="primary"
            >
              <Tab label={`Pendientes (${pendingMatches?.length || 0})`} />
              <Tab label={`Activos (${activeMatches?.length || 0})`} />
              <Tab
                label={
                  userData?.type === 0
                    ? `Matcheados (${approvedMatches?.length || 0})`
                    : `Aceptados (${approvedMatches?.length || 0})`
                }
              />
              <Tab label={`Completados (${completedMatches?.length || 0})`} />
              <Tab label={`Rechazados (${rejectedMatches?.length || 0})`} />
              <Tab label={`Cancelados (${cancelledMatches?.length || 0})`} />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              <div className={classes.tabPanel}>
                {pendingMatches.length > 0 ? (
                  <React.Fragment>
                    <Alert severity="info" style={{ marginBottom: 10 }}>
                      {userData?.type === 0 ? (
                        <div>
                          Estos son quieros que has solicitado directamente a algún proveedor, se encuentran en proceso
                          de desición por parte de los proveedores, si aceptan tu match se convertirá en un match
                          aceptado.
                        </div>
                      ) : (
                        <div>
                          Estos son matches que te han solicitado, revisalos y aceptalos si te interesan o bien
                          rechazalos.
                        </div>
                      )}
                    </Alert>
                    {pendingMatches.map((match, index) => (
                      <MatchItem key={index} match={match} onClick={goToMatch(match.id)} />
                    ))}
                  </React.Fragment>
                ) : (
                  <EmptyState message="No tienes matches pendientes." />
                )}
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <div className={classes.tabPanel}>
                {activeMatches.length > 0 ? (
                  <React.Fragment>
                    <Alert severity="info" style={{ marginBottom: 10 }}>
                      {userData?.type === 0 ? (
                        <div>
                          Estos son quieros que tu has enviado de forma general y se encuentran a la espera de que tu
                          decidas completarlos o no en base a los proveedores que hayan decidido matchear.
                        </div>
                      ) : (
                        <div>
                          Estos son matches que tu has enviado y se encuentran en proceso de desición por parte del
                          usuario, si aceptan tu match se convertirá en un match aceptado.
                        </div>
                      )}
                    </Alert>
                    {activeMatches.map((match, index) => (
                      <MatchItem key={index} match={match} onClick={goToMatch(match.id)} />
                    ))}
                  </React.Fragment>
                ) : (
                  <EmptyState message="No tienes matches activos." />
                )}
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <div className={classes.tabPanel}>
                {approvedMatches.length > 0 ? (
                  approvedMatches.map((match, index) => (
                    <MatchItem key={index} match={match} onClick={goToMatch(match.id)} />
                  ))
                ) : (
                  <EmptyState message="No tienes matches." />
                )}
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <div className={classes.tabPanel}>
                {completedMatches.length > 0 ? (
                  completedMatches.map((match, index) => (
                    <MatchItem
                      key={index}
                      match={match}
                      readOnly
                      askForRate={userData?.type === 0 ? !match.user_rate : !match.provider_rate}
                    />
                  ))
                ) : (
                  <EmptyState message="No tienes matches completados." />
                )}
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <div className={classes.tabPanel}>
                {rejectedMatches.length > 0 ? (
                  rejectedMatches.map((match, index) => (
                    <MatchItem key={index} match={match} onClick={goToMatch(match.id)} />
                  ))
                ) : (
                  <EmptyState message="No tienes matches rechazados." />
                )}
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
              <div className={classes.tabPanel}>
                {cancelledMatches.length > 0 ? (
                  cancelledMatches.map((match, index) => (
                    <MatchItem key={index} match={match} onClick={goToMatch(match.id)} />
                  ))
                ) : (
                  <EmptyState message="No tienes matches cancelados." />
                )}
              </div>
            </TabPanel>
          </div>
        </div>
      </Container>
      <Footer />
    </Page>
  );
};

HomeScreen.id = 'com.QuieroMatch.Home';

export default HomeScreen;
