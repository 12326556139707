import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  EmptyState,
  Footer,
  Header,
  HowItWorks,
  MatchCard,
  MatchProfile,
  Page,
  SearchBar,
} from '../../components';
import qs from 'query-string';
import makeClasses from './styles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { common as commonActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import { getDocs, where, query, collection, getDoc, doc, updateDoc } from 'firebase/firestore';
import _ from 'lodash';
import { convertProfileToMatch, convertProfileToWant } from '../../utils/Utils';
import ClearIcon from '@material-ui/icons/Clear';

const SearchScreen = ({ history }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { db, auth } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();
  const searchParams = new URLSearchParams(window.location.search);

  const { userData, loading } = useSelector((state) => ({
    userData: state.user.data || null,
    loading: state.common.loading,
  }));

  const [matches, setMatches] = useState([]);
  const [profile, setProfile] = useState(null);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if (db) {
      if (searchParams.getAll('f')?.length > 0) {
        setSelectedFilters(searchParams.getAll('f'));
      }
      if (searchParams.getAll('q') && !loading) {
        search(searchParams.getAll('q'));
      }
    }
  }, [db]);

  useEffect(() => {
    if (matches?.length > 0) {
      if (searchParams.get('m')) {
        const match = matches.find((match) => match.id === searchParams.get('m'));
        if (match) {
          toggleProfileInfo(match)();
        }
      }
      const filters = _.uniq(
        _.flatten(
          matches.map((match) => match?.category_data?.[searchParams.get('q')]?.skills || match?.skills || [])
        ).map((f) => (typeof f !== 'string' ? f.name : f))
      );
      setFilters(filters);
    }
  }, [matches]);

  const toggleProfileInfo =
    (match = null) =>
    () => {
      setProfile(match);
      setShowProfileInfo(!!match);
    };

  const search = async (searchParam) => {
    try {
      dispatch(commonActions.setLoading(true));
      if (userData?.type === 1) {
        let matches = [];
        for (const param of searchParam) {
          const queryRef = query(
            collection(db, 'matches'),
            where('category_id', '==', param),
            where('status', '==', 'active')
          );
          const docs = await getDocs(queryRef);
          const docsMatches = docs.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((doc) => !doc.matches?.includes(auth.currentUser.uid));
          for (const match of docsMatches) {
            const matchProfileRef = await getDoc(doc(collection(db, 'users'), match.user_id));
            const docData = matchProfileRef.data();
            if (!match.matches?.includes(auth.currentUser.uid)) {
              matches.push(convertProfileToWant(matchProfileRef.id, match.ids, docData, match));
            }
          }
        }
        setMatches(matches);
      } else {
        let matches = [];
        for (const param of searchParam) {
          const queryRef = query(
            collection(db, 'users'),
            where('categories', 'array-contains', param),
            where('active', '==', true),
            where('type', '==', 1),
            where('profile_completed', '==', true)
          );
          const docs = await getDocs(queryRef);
          docs.forEach((doc) => {
            const docData = doc.data();
            if (!matches.some((match) => match.id === doc.id)) {
              // avoid duplicates
              matches.push(convertProfileToMatch(doc.id, docData));
            }
          });
        }
        setMatches(matches);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(commonActions.setLoading(false));
    }
  };

  const goToSearch = (initialSearchParam) => {
    let searchParam = '';
    if (Array.isArray(initialSearchParam)) {
      searchParam = initialSearchParam.map((q) => `q=${q}`).join('&');
    } else {
      searchParam = `q=${initialSearchParam}`;
    }
    dispatch(push('/search?' + searchParam));
    search(initialSearchParam);
  };

  const findMatch = () => {
    if (searchParams.getAll('q')?.length > 1) {
      dispatch(push('/match-by-category/new'));
    } else {
      dispatch(push('/match-by-category/' + searchParams.getAll('q') || 'new'));
    }
  };

  const matchProfile = (profileId) => () => {
    dispatch(push('/match/' + profileId + '?category=' + searchParams.get('q')));
  };

  const goToMatches = () => {
    if (userData?.type === 1) {
      dispatch(push('/home?tab=1'));
    } else {
      dispatch(push('/home?tab=0'));
    }
  };

  const handleOnMatch = async (profile, budget) => {
    if (userData?.type === 1) {
      setShowProfileInfo(false);
      dispatch(commonActions.setLoading(true));

      try {
        const data = {
          matches:
            profile?.match?.matches?.length > 0
              ? [...profile?.match?.matches, auth.currentUser.uid]
              : [auth.currentUser.uid],
        };

        if (profile?.match?.requires_budget && budget) {
          data.budget =
            profile?.match?.budget?.length > 0
              ? [...profile?.match?.budget, { user_id: auth.currentUser.uid, budget }]
              : [{ user_id: auth.currentUser.uid, budget }];
        }

        await updateDoc(doc(db, 'matches', profile?.match?.id), data);

        notifySuccess('Enviamos tu solicitud de match. ¡Te avisaremos si la acepta!');

        setTimeout(() => {
          goToMatches();
        }, 500);
      } catch (error) {
        notifyError(error.message);
      }
      dispatch(commonActions.setLoading(false));
    } else {
      matchProfile(profile.id)();
    }
  };

  const handleOnFilterChange =
    (filter, clear = false) =>
    () => {
      if (clear) {
        setSelectedFilters([]);
        dispatch(push('/search?' + qs.stringify({ q: searchParams.get('q') })));
        return;
      }

      let newSelectedFilters = [...selectedFilters];
      if (newSelectedFilters.includes(filter)) {
        newSelectedFilters = newSelectedFilters.filter((f) => f !== filter);
        setSelectedFilters(newSelectedFilters);
      } else {
        newSelectedFilters = [...newSelectedFilters, filter];
        setSelectedFilters(newSelectedFilters);
      }

      const localSearchParams = qs.parse(history.location.search) || {};
      localSearchParams.f = newSelectedFilters;

      dispatch(
        push({
          search: qs.stringify(localSearchParams),
        })
      );
    };

  return (
    <Page>
      <Header size="md" withBorder showSearchBar={false} />
      <Container>
        <div className={classes.searchResultsContainer}>
          <div className={classes.searchBarWrapper}>
            <SearchBar onSearch={goToSearch} value={searchParams?.getAll('q')} />
          </div>
          <div className={classes.searchResultsCta}>
            {userData?.type === 1 ? (
              <div>
                {matches.length === 1
                  ? `Tenés 1 posible quiero para matchear.`
                  : `Tenés ${matches.length} posibles quieros para matchear.`}
              </div>
            ) : (
              <div>{matches.length === 1 ? `Tenés 1 posible match.` : `Tenés ${matches.length} posibles matches.`}</div>
            )}
            <div>
              {userData?.type === 1 ? (
                <Button type="outlined" onClick={goToMatches}>
                  Ir a tus matches
                </Button>
              ) : (
                <Button type="green" onClick={findMatch}>
                  Encontrá tu match
                </Button>
              )}
            </div>
          </div>
          {filters.length > 0 ? (
            <div className={classes.filtersContainer}>
              <div className={classes.filtersTitle}>Filtros</div>
              <div className={classes.filtersWrapper}>
                <Button
                  size="xs"
                  type="outlined"
                  variant="orange"
                  onClick={handleOnFilterChange(null, true)}
                  disabled={selectedFilters.length === 0}
                >
                  Limpiar
                </Button>
                {filters.map((filter, index) => (
                  <Button
                    key={index}
                    size="xs"
                    type={selectedFilters.includes(filter) ? 'orange' : 'outlined'}
                    onClick={handleOnFilterChange(filter)}
                  >
                    {filter}
                  </Button>
                ))}
              </div>
            </div>
          ) : null}
          <Grid container spacing={3}>
            {matches?.length > 0 ? (
              matches
                .filter((m) =>
                  selectedFilters.length === 0
                    ? true
                    : _.intersection(
                        selectedFilters,
                        m.category_data?.[searchParams.get('q')]?.skills || m.skills?.map((s) => s.name) || []
                      ).length > 0
                )
                .map((match, index) => (
                  <Grid item xs={12} sm={12} md={3} key={index}>
                    <MatchCard
                      match={match}
                      onClickProfile={toggleProfileInfo(match)}
                      onClickMatch={matchProfile(match.id)}
                    />
                  </Grid>
                ))
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                {userData?.type === 1 ? (
                  <EmptyState message="No hay quieros al momento para matchear." />
                ) : (
                  <EmptyState message="No hay matches al momento." />
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </Container>
      <MatchProfile
        open={showProfileInfo}
        onClose={toggleProfileInfo()}
        onMatch={handleOnMatch}
        profile={profile}
        categoryId={searchParams.get('q')}
      />
      <HowItWorks />
      <Footer />
    </Page>
  );
};

SearchScreen.id = 'com.QuieroMatch.Search';

export default SearchScreen;
