import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  searchResultsCta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 10px',
    marginBottom: 20,
    color: '#333',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  searchResultsContainer: {
    width: '100%',
    minHeight: 'calc(100vh - 90px - 55px)',
    boxSizing: 'border-box',
    paddingTop: 20,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflow: 'hidden',
    },
  },
  contestCtaContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: 'red',
    top: '50%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: 230,
    boxSizing: 'border-box',
    background: 'linear-gradient(to top, white 70%, transparent)',
    left: 0,
    right: 0,
  },
  searchBarWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  filtersContainer: {
    margin: '0 0 30px',
  },
  filtersTitle: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 10,
  },
  filtersWrapper: {
    '& > button': {
      marginRight: 10,
      marginBottom: 7,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

export default makeClasses;
