import * as types from './User.actionTypes';

export const initialize = (callback) => {
  return {
    type: types.INITIALIZE,
    callback,
  };
};

export const setLoading = (value) => {
  return {
    type: types.SET_LOADING,
    value,
  };
};

export const setData = (data) => {
  return {
    type: types.SET_DATA,
    data,
  };
};

export const setToken = (token) => {
  return {
    type: types.SET_TOKEN,
    token,
  };
};

export const logOut = () => {
  return {
    type: types.LOG_OUT,
  };
};
