import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 90px - 55px)',
    padding: '40px 0 60px',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
  },
  mainMessageContainer: {
    color: '#333',
    fontWeight: '400',
  },
  messageContainer: {
    color: '#555',
    fontWeight: '300',
  },
  formContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  introductionContainer: {
    marginBottom: 40,
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  image: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 200,
    width: 200,
    height: 200,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    '& > div': {
      fontSize: 54,
      color: '#555',
      fontWeight: 300,
    },
  },
  imageWithBackground: {
    backgroundColor: '#f5f5f5',
  },
  imageUploadWrapper: {
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  activateAccountContainer: {
    '& a': {
      color: 'rgb(102, 60, 0)',
      fontWeight: '500',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formSectionWrapper: {
    marginTop: 30,
    marginBottom: 50,
  },
  additionalInformationTitleWrapper: {
    color: '#555',
    marginTop: 30,
    fontWeight: '300',
  },
  itemSeparator: {
    width: '100%',
    height: 1,
    background: 'rgba(0,0,0,0.1)',
    margin: '20px 0',
  },
  profileSectionSeparator: {
    height: 30,
  },
  tabsContainer: {
    display: 'flex',
    marginTop: 40,

    '& [role="tabpanel"]': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: '0 20px',

      '&[hidden]': {
        display: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      '& [role="tabpanel"]': {
        padding: 0,
      },
    },
  },
  tabs: {
    borderRight: `1px solid rgba(0,0,0,0.1)`,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
  },
  tabPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    boxSizing: 'border-box',
  },
}));

export default makeClasses;
