import React, { useEffect, useRef, useState } from 'react';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import makeClasses from './styles';
import { useToasts } from '../../hooks';
import clsx from 'clsx';

const MAX_IMAGES = 5;

const ImageGrid = ({ images, onChange }) => {
  const classes = makeClasses();
  const fileInputRefs = Array(MAX_IMAGES).fill(null);
  const { notifyError } = useToasts();

  const [availableImages, setAvailableImages] = useState([]);

  useEffect(() => {
    const imagesToAdd = [];
    for (let i = 0; i < MAX_IMAGES; i++) {
      if (images[i]) {
        imagesToAdd.push({ img: images[i], file: null });
      } else {
        imagesToAdd.push(null);
      }
    }
    setAvailableImages(imagesToAdd);
  }, []);

  const handleOnFileUpload = (index) => () => {
    if (fileInputRefs[index]) {
      fileInputRefs[index].click();
    }
  };

  const handleFileUploadOnChange = (index) => (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        notifyError('La imágen no puede superar los 3MB de tamaño.');
        return;
      }

      // Create a FileReader to read the file
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const newAvailableImages = availableImages.slice();
        newAvailableImages[index] = { img: event.target.result, file };
        setAvailableImages(newAvailableImages);
        onChange(newAvailableImages);
      });

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleOnRemoveImage = (index) => () => {
    const newAvailableImages = availableImages.slice();
    newAvailableImages[index] = null;
    setAvailableImages(newAvailableImages);
    onChange(newAvailableImages);
  };

  return (
    <PhotoProvider>
      <div className={classes.container}>
        {availableImages.map((img, index) => (
          <div
            key={index}
            onClick={handleOnFileUpload(index)}
            className={clsx(classes.imageWrapper, img ? classes.imageWrapperWithImage : null)}
          >
            {img ? (
              <div>
                <PhotoView src={img.img}>
                  <img src={img.img} />
                </PhotoView>
                <div className={classes.removeButton} onClick={handleOnRemoveImage(index)}>
                  <RemoveCircleIcon />
                </div>
              </div>
            ) : (
              <div className={classes.imagePlaceholder}>
                <AddAPhotoIcon />
                <input
                  type="file"
                  ref={(ref) => (fileInputRefs[index] = ref)}
                  onChange={handleFileUploadOnChange(index)}
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </PhotoProvider>
  );
};

export default ImageGrid;
