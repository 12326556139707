import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import configureStore from './store/configureStore';
import App from './containers/App';
import theme from './theme';
import { Toaster } from 'react-hot-toast';

import './index.css';
import { ThemeProvider } from '@material-ui/core';

const history = createHistory();
const initialState = {};

const { store, persistor } = configureStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <Toaster />
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
