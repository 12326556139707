import Constants from './Constants';
//import { Auth } from 'aws-amplify';

const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const getToken = async () => {
  // const session = await Auth.currentSession();
  // const authIdToken = session.getIdToken().getJwtToken();
  // return authIdToken;
  return null;
};

const API = {
  get: async (path = '', params = {}, isPrivate = true) => {
    try {
      const token = isPrivate ? await getToken() : null;
      const apiPath = isPrivate ? Constants.API_PRIVATE : Constants.API_PUBLIC;
      const hasParams = Object.keys(params).length > 0;
      const response = await fetch(
        hasParams
          ? `${apiPath}${path}?${Object.keys(params)
              .map((p) => `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`)
              .join('&')}`
          : `${apiPath}${path}`,
        {
          method: 'GET',
          headers: {
            ...COMMON_HEADERS,
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        try {
          const data = await response.json();
          return { status: 'success', code: response.status, data };
        } catch (e) {
          return { status: 'success', code: response.status };
        }
      }

      try {
        const error = await response.text();
        return { status: 'error', message: JSON.parse(error), code: response.status };
      } catch (e) {
        return { status: 'error', code: response.status };
      }
    } catch (e) {
      return { status: 'error', message: e, code: e.code || null };
    }
  },

  post: async (path = '', options = {}, isPrivate = true) => {
    try {
      const token = await getToken();
      const apiPath = isPrivate ? Constants.API_PRIVATE : Constants.API_PUBLIC;
      const response = await fetch(`${apiPath}${path}`, {
        method: 'POST',
        headers: {
          ...COMMON_HEADERS,
          Authorization: token,
        },
        body: JSON.stringify(options),
      });

      if (response.status === 200) {
        try {
          const data = await response.json();
          return { status: 'success', code: response.status, data };
        } catch (e) {
          return { status: 'success', code: response.status };
        }
      }

      try {
        const error = await response.text();
        return { status: 'error', message: JSON.parse(error), code: response.status };
      } catch (e) {
        return { status: 'error', code: response.status };
      }
    } catch (e) {
      return { status: 'error', message: e, code: e.code || null };
    }
  },

  put: async (path = '', options = {}, isPrivate = true) => {
    try {
      const token = await getToken();
      const apiPath = isPrivate ? Constants.API_PRIVATE : Constants.API_PUBLIC;
      const response = await fetch(`${apiPath}${path}`, {
        method: 'PUT',
        headers: {
          ...COMMON_HEADERS,
          Authorization: token,
        },
        body: JSON.stringify(options),
      });

      if (response.status === 200) {
        try {
          const data = await response.json();
          return { status: 'success', code: response.status, data };
        } catch (e) {
          return { status: 'success', code: response.status };
        }
      }

      try {
        const error = await response.text();
        return { status: 'error', message: JSON.parse(error), code: response.status };
      } catch (e) {
        return { status: 'error', code: response.status };
      }
    } catch (e) {
      return { status: 'error', message: e, code: e.code || null };
    }
  },

  delete: async (path = '', options = {}, isPrivate = true) => {
    try {
      const token = await getToken();
      const apiPath = isPrivate ? Constants.API_PRIVATE : Constants.API_PUBLIC;
      const response = await fetch(`${apiPath}${path}`, {
        method: 'DELETE',
        headers: {
          ...COMMON_HEADERS,
          Authorization: token,
        },
        body: JSON.stringify(options),
      });

      if (response.status === 200) {
        try {
          const data = await response.json();
          return { status: 'success', code: response.status, data };
        } catch (e) {
          return { status: 'success', code: response.status };
        }
      }

      try {
        const error = await response.text();
        return { status: 'error', message: JSON.parse(error), code: response.status };
      } catch (e) {
        return { status: 'error', code: response.status };
      }
    } catch (e) {
      return { status: 'error', message: e, code: e.code || null };
    }
  },
};

export default API;
