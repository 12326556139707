import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background: '#fff',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default makeClasses;
