import React, { useState } from 'react';

import makeClasses from './styles';
import { Hidden } from '@material-ui/core';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import SearchBar from '../SearchBar';
import AppLogo from '../AppLogo';
import clsx from 'clsx';
import { user as userActions } from '../../actions';
import { useFirebase } from '../../hooks';
import Popover from '@material-ui/core/Popover';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function Header({ showAppLogo = true, showSearchBar = true, onSearch, size = 'sm', withBorder = false }) {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { signOut } = useFirebase();

  const { userSignedIn, userData, queryParams } = useSelector((state) => ({
    userSignedIn: state.user?.token ? true : false,
    userData: state.user?.data || null,
    queryParams: new URLSearchParams(state.router.location.search),
  }));
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const open = Boolean(profileAnchorEl);

  const handleOnClickProfile = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleOnCloseProfile = () => {
    setProfileAnchorEl(null);
  };

  const goToLogin = () => {
    dispatch(push('/login'));
  };

  const goToRegister = () => {
    dispatch(push('/register'));
  };

  const goToHome = () => {
    handleOnCloseProfile();
    dispatch(push('/home'));
  };

  const goToProfile = () => {
    handleOnCloseProfile();
    dispatch(push('/profile'));
  };

  const handleLogOut = async () => {
    handleOnCloseProfile();
    dispatch(userActions.setLoading(true));
    signOut();
    dispatch(userActions.logOut());
    dispatch(userActions.setLoading(false));
    dispatch(push('/'));
  };

  return (
    <div
      className={clsx(
        classes.wrapper,
        size === 'md' ? classes.wrapperMd : null,
        withBorder ? classes.wrapperWithBorder : null
      )}
    >
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.headerLeftWrapper}>
            {showAppLogo ? <AppLogo /> : null}
            {showSearchBar ? <SearchBar value={queryParams.getAll('q')} onSearch={onSearch} /> : null}
          </div>
          <div>
            {userSignedIn ? (
              <React.Fragment>
                <div className={classes.profileImageContainer} onClick={handleOnClickProfile}>
                  {userData?.profile_image ? (
                    <img src={userData?.profile_image} />
                  ) : (
                    <div className={classes.profileImage}>
                      {`${userData?.first_name?.substring(0, 1)}${userData?.last_name?.substring(0, 1)}`.toUpperCase()}
                    </div>
                  )}
                </div>
                <Popover
                  open={open}
                  anchorEl={profileAnchorEl}
                  onClose={handleOnCloseProfile}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <ul className={classes.profileMenu}>
                    <li onClick={goToHome}>
                      <HomeIcon />
                      {userData?.type === 1 ? <span>Tus matches</span> : <span>Tus quieros</span>}
                    </li>
                    <li onClick={goToProfile}>
                      <AccountCircleIcon />
                      Perfil
                    </li>
                    <li onClick={handleLogOut}>
                      <ExitToAppIcon />
                      Salir
                    </li>
                  </ul>
                </Popover>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button onClick={goToLogin}>Quiero Ingresar</Button>
                <Button type="green" onClick={goToRegister}>
                  Quiero Registrarme
                </Button>
              </React.Fragment>
            )}
          </div>
        </Hidden>
        <Hidden mdUp>
          {showAppLogo ? <AppLogo variant="small" /> : <div />}
          <div>
            {userSignedIn ? (
              <React.Fragment>
                <div className={classes.profileImageContainer} onClick={handleOnClickProfile}>
                  {userData?.profile_image ? (
                    <img src={userData?.profile_image} />
                  ) : (
                    <div className={classes.profileImage}>
                      {`${userData?.first_name?.substring(0, 1)}${userData?.last_name?.substring(0, 1)}`.toUpperCase()}
                    </div>
                  )}
                </div>
                <Popover
                  open={open}
                  anchorEl={profileAnchorEl}
                  onClose={handleOnCloseProfile}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <ul className={classes.profileMenu}>
                    <li onClick={goToHome}>
                      <HomeIcon />
                      {userData?.type === 1 ? <span>Tus matches</span> : <span>Tus quieros</span>}
                    </li>
                    <li onClick={goToProfile}>
                      <AccountCircleIcon />
                      Perfil
                    </li>
                    <li onClick={handleLogOut}>
                      <ExitToAppIcon />
                      Salir
                    </li>
                  </ul>
                </Popover>
              </React.Fragment>
            ) : (
              <Button type="green" onClick={goToLogin}>
                Quiero Ingresar
              </Button>
            )}
          </div>
        </Hidden>
      </div>
      {showSearchBar ? (
        <Hidden mdUp>
          <div className={classes.searchBarContainerSm}>
            <SearchBar value={queryParams.getAll('q')} onSearch={onSearch} />
          </div>
        </Hidden>
      ) : null}
    </div>
  );
}

export default Header;
