import React from 'react';

import makeClasses from './styles';

const EmptyState = ({ message }) => {
  const classes = makeClasses();

  return (
    <div className={classes.container}>
      <div className={classes.message}>{message}</div>
    </div>
  );
};

export default EmptyState;
