import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    padding: 20,
    display: 'flex',
    border: '1px solid rgba(0,0,0,0.1)',
    width: '100%',
    marginBottom: 10,
    borderRadius: 7,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    flexDirection: 'column',
    boxSizing: 'border-box',

    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.2)',
      opacity: 0.8,
    },

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  readOnly: {
    cursor: 'default',

    '&:hover': {
      boxShadow: 'none',
      opacity: 1,
    },
  },
  contestMatch: {
    background: '#E0F7FA',
  },
  directMatch: {
    background: '#FFF3E0',
  },
  itemName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    '& > span': {
      fontSize: 16,
      color: '#555',
      fontWeight: '300',
      marginRight: 5,
    },
  },
  itemDescription: {
    fontSize: 16,
    fontWeight: '300',
    color: '#555',
    marginBottom: 10,
  },
  itemMatches: {
    fontSize: 14,
    fontWeight: '300',
    color: '#555',
    marginBottom: 10,
  },
  itemTime: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,

    '& > div': {
      fontSize: 14,
      fontWeight: '300',
      color: '#555',

      '& > span': {
        fontSize: 14,
        color: '#333',
        fontWeight: 'bold',
        marginRight: 5,
      },
    },
  },
  rateButtonContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    marginTop: 15,
    paddingTop: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: '500',
    fontWeight: '300',
    color: '#333',
  },
  rateIcon: {
    marginRight: 5,
    color: '#FF6F00',
  },
}));

export default makeClasses;
