import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  wrapper: {
    height: 74,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',

    [theme.breakpoints.between('md', 'xl')]: {
      maxWidth: '80%',
    },

    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1200,
    },
  },
  wrapperWithBorder: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  profileImageContainer: {
    width: 40,
    height: 40,
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    border: '1px solid rgba(0,0,0,0.3)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    '&:hover': {
      opacity: 0.8,
    },
  },
  profileImage: {
    background: '#f5f5f5',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: '#333',
  },
  headerLeftWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > a': {
      marginRight: 30,
    },
  },
  searchBarContainerSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
  },
  profileMenu: {
    padding: 10,
    margin: 0,
    width: 150,

    '& > li': {
      listStyle: 'none',
      padding: '10px 5px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      borderRadius: 5,
      color: '#555 !important',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,

      '& > svg': {
        marginRight: 5,
      },

      '&:last-child': {
        borderTop: '1px solid rgba(0,0,0,0.1)',
      },

      '&:hover': {
        background: '#FFF3E0',
      },
    },
  },
}));

export default makeClasses;
