import React from 'react';
import clsx from 'clsx';
import makeClasses from './styles';

const appLogo = require('../../static/images/logo.svg');
const appLogoSm = require('../../static/images/logo-sm.svg');

const AppLogo = ({ variant }) => {
  const classes = makeClasses();

  return (
    <a className={classes.logoContainer} href="/">
      <img
        src={variant === 'small' ? appLogoSm : appLogo}
        className={clsx(classes.logo, variant === 'small' && classes.small)}
      />
    </a>
  );
};

export default AppLogo;
