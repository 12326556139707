import moment from 'moment';
import API from '../Api';

let i18n = {};
let locale = 'es';
let requestingTexts = null;

/*if (navigator.languages && navigator.languages.length) {
	locale = navigator.languages[0];
} else {
	locale = navigator.language || navigator.browserLanguage || 'es';
}

if (locale.indexOf('-') >= 0) {
	locale = locale.split('-')[0];
} else if (locale.indexOf('_') >= 0) {
	locale = locale.split('_')[0];
}*/

export const getLocale = () => {
	const hasTexts = Object.keys(i18n).length > 0;

	if (hasTexts) {
		return i18n;
	}

	if (requestingTexts) {
		return requestingTexts;
	}

	requestingTexts = new Promise(async (resolve) => {
		try {
			const result = await API.get(`/languages/${locale.toUpperCase()}`, {}, false);

			if (result.status === 'success' && result.data) {
				i18n = result.data;
			} else {
				throw result.message;
			}
		} catch (e) {
			i18n = require('./locales/es_es.json');
		}

		resolve(i18n);
	});

	return requestingTexts;
};

export const setMomentLocale = async (locale) => {
	switch (locale) {
		case 'es':
			require('../../../node_modules/moment/locale/es');
			break;
		default:
			require('../../../node_modules/moment/locale/es');
			break;
	}

	moment.locale(locale);
};

setMomentLocale(locale);
