import React from 'react';
import makeClasses from './styles';

const Container = ({ background, children }) => {
  const classes = makeClasses();
  return (
    <div className={classes.wrapper} style={{ background }}>
      <div className={classes.container}>{children}</div>
    </div>
  );
};

export default Container;
