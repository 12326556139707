export const rgba = (color, alpha) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
	const r = parseInt(result[1], 16);
	const g = parseInt(result[2], 16);
	const b = parseInt(result[3], 16);

	return result ? `rgba(${r}, ${g}, ${b}, ${alpha / 100})` : null;
};

export const rgb = {
	primary: '#005364',
	white: '#ffffff',
	black: '#000000',
	light_black: '#2D2D2D',
	blue: '#6E28FA',
	dark_blue: '#5717D8',
	light_blue: '#2BB9F5',
	red: '#D0021B',
	red_dark: '#c62828',
	soft_red: '#FFF5F6',
	green: '#82C43A',
	green_dark: '#417107',
	yellow: '#ffee58',
	yellow_dark: '#f9a825',
	gray: '#e8e8e8',
	dark_gray: '#5f6368',
	medium_gray: '#e4e4e4',
	light_gray: '#f2f2f2',
	transparent: 'transparent',
	page_background: '#EDEDED',
	orange: '#F36E14'
};
