import { commonTypes as types, userTypes } from '../actions';

const initialState = {
  loading: false,
  locale: 'es',
};

export default function common(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case types.CLEAR:
    case userTypes.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
