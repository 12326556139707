import React, { useEffect, useState } from 'react';
import makeClasses from './styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../Button';
import { useFirebase } from '../../hooks';
import { collection, getDocs, query, where } from 'firebase/firestore';
import SendIcon from '@material-ui/icons/Send';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { common as commonActions } from '../../actions';
import { TextField } from '@material-ui/core';

function SearchBar({ value, onSearch }) {
  const classes = makeClasses();
  const { db } = useFirebase();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);

  const { userData, loading } = useSelector((state) => ({
    userData: state.user.data || null,
    loading: state.common.loading,
  }));

  useEffect(() => {
    const getCategories = async () => {
      dispatch(commonActions.setLoading(true));
      const categories = await getDocs(query(collection(db, 'categories'), where('providers', '>', 0)));
      const categoriesDocs = categories.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCategories(_.orderBy(categoriesDocs, ['quiero_name'], ['asc']));
      dispatch(commonActions.setLoading(false));
      if (value) {
        setCategory(categoriesDocs.filter((c) => value.includes(c.id))[0]);
      }
    };
    if (db && !categories.length && !loading) {
      getCategories();
    }
  }, [db, value]);

  const handleOnClick = () => {
    if (typeof onSearch !== 'function') {
      return;
    }
    if (Array.isArray(category) && category?.length > 0) {
      onSearch(category.map((c) => c.id));
    } else if (category) {
      onSearch([category.id]);
    }
  };

  if (userData?.type === 1) {
    return null;
  }

  return (
    <div className={classes.bubblesContainer}>
      <div className={classes.placeholder}>Quiero</div>
      <Autocomplete
        key={category?.length}
        options={categories}
        getOptionLabel={(option) => option.quiero_name}
        onChange={(e, value) => setCategory(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Algo que empieza con..."
            variant="outlined"
            className={classes.formInput}
            classes={{ root: classes.formInput }}
          />
        )}
        defaultValue={category}
        multiple={false}
        disableClearable
        className={classes.input}
        classes={{ inputRoot: classes.formInputSelect }}
      />
      <Button type="orange" disabled={category?.length === 0} onClick={handleOnClick}>
        <SendIcon />
      </Button>
    </div>
  );
}

export default SearchBar;
