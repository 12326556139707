import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';

import makeClasses from './styles';
import clsx from 'clsx';

const TextInput = ({ label, value, onChange, placeholder, password, endAdornment, limitMinDate = false, ...props }) => {
  const classes = makeClasses();
  const today = new Date();
  const minDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate()
  ).padStart(2, '0')}`;

  return (
    <div className={classes.inputContainer}>
      <TextField
        label={label}
        value={value}
        className={clsx(classes.input, endAdornment ? classes.inputWithEndAdornment : null)}
        placeholder={placeholder}
        variant="outlined"
        onChange={onChange}
        type={password ? 'password' : 'text'}
        inputProps={props.type === 'date' && limitMinDate ? { min: minDate } : {}}
        {...props}
      />
      {endAdornment ? (
        <div className={classes.inputEndAdornment}>
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        </div>
      ) : null}
    </div>
  );
};

export default TextInput;
