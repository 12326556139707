import React from 'react';
import { Footer, Header, HowItWorks, Page, SearchBar, Slogan } from '../../components';

import makeClasses from './styles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const LandingScreen = () => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const goToSearch = (query) => {
    if (Array.isArray(query)) {
      query = query.map((q) => `q=${q}`).join('&');
    } else {
      query = `q=${query}`;
    }
    dispatch(push('/search?' + query));
  };

  return (
    <Page>
      <Header showSearchBar={false} withBorder />
      <div className={classes.heroContainer}>
        <div className={classes.slogan}>
          <span>Donde tu quiero</span>
          <span>encuentra su match</span>
        </div>
        <div className={classes.searchBarWrapper}>
          <SearchBar onSearch={goToSearch} />
        </div>
      </div>
      <Slogan />
      <HowItWorks />
      <Footer />
    </Page>
  );
};

LandingScreen.id = 'com.QuieroMatch.Landing';

export default LandingScreen;
