import React, { useEffect, useState } from 'react';
import { Button, Header, Page, TextInput } from '../../components';
import makeClasses from './styles';
import { Grid, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { common as commonActions, user as userActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { replace } from 'connected-react-router';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const RegisterScreen = () => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { auth, db } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();

  const [selectedOption, setSelectedOption] = useState(-1);
  const [registrationStep, setRegistrationStep] = useState(0);
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [inputPasswordError, setInputPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputCountry, setInputCountry] = useState('');
  const [inputCategories, setInputCategories] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);

  const { loading } = useSelector((state) => ({ loading: state.common.loading || state.user.loading }));

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+\[\]{};:,.<>/?|~\\])[A-Za-z\d!@#$%^&*()-_=+\[\]{};:,.<>/?|~\\]{8,}$/;
  const canSubmitForm =
    !loading &&
    inputEmail?.length > 0 &&
    inputPassword?.length > 0 &&
    !inputPasswordError &&
    inputCountry?.length > 0 &&
    inputFirstName?.length > 0 &&
    inputLastName?.length > 0 &&
    inputPhone?.length > 0 &&
    (selectedOption === 0 || (selectedOption === 1 && inputCategories?.length > 0));

  useEffect(() => {
    if (db && registrationStep === 1) {
      getData();
    }
  }, [registrationStep, db]);

  const getData = async () => {
    dispatch(commonActions.setLoading(true));

    const countries = await getDocs(collection(db, 'countries'));
    setCountries(countries.docs.map((doc) => doc.data()));

    const categories = await getDocs(collection(db, 'categories'));
    setCategories(categories.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

    dispatch(commonActions.setLoading(false));
  };

  const handleOnItemClick = (option) => () => {
    setSelectedOption(option);
  };

  const handleOnSetRegistrationStep = (step) => () => {
    setRegistrationStep(step);
  };

  const submit = async () => {
    dispatch(commonActions.setLoading(true));
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, inputEmail, inputPassword);
      const user = userCredential.user;
      const docRef = doc(db, 'users', user.uid);

      const data = {
        first_name: inputFirstName,
        last_name: inputLastName,
        email: inputEmail,
        country: inputCountry,
        categories: selectedOption === 1 ? inputCategories : null,
        type: selectedOption,
        profile_completed: false,
        phone: inputPhone || '',
      };
      await setDoc(docRef, data);

      // Store the user token in local storage
      const idToken = await user.getIdToken(true);

      dispatch(userActions.setToken(idToken));
      dispatch(userActions.setData({ ...data, in_review: true }));

      await sendEmailVerification(auth.currentUser);

      notifySuccess('Usuario creado correctamente');

      setTimeout(() => {
        window.scrollTo(0, 0);
        dispatch(replace('/profile'));
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    } finally {
      dispatch(commonActions.setLoading(false));
    }
  };

  const renderTitle = () => {
    if (registrationStep === 1) {
      if (selectedOption === 0) {
        return <span>Registrate como cliente</span>;
      }
      return <span>Registrate como proveedor</span>;
    }
    return <span>Registrate como cliente o proveedor</span>;
  };

  const renderRegistrationSteps = () => {
    if (registrationStep === 0) {
      return (
        <React.Fragment>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <div
                className={clsx(classes.registerItem, selectedOption === 0 ? classes.registerItemActive : null)}
                onClick={handleOnItemClick(0)}
              >
                <div
                  className={clsx(
                    classes.registerItemIndicator,
                    selectedOption === 0 ? classes.registerItemIndicatorActive : null
                  )}
                >
                  {selectedOption === 0 ? <div className={classes.registerItemIndicatorSelected} /> : null}
                </div>
                <div>Soy un cliente, quiero encontrar match.</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div
                className={clsx(classes.registerItem, selectedOption === 1 ? classes.registerItemActive : null)}
                onClick={handleOnItemClick(1)}
              >
                <div
                  className={clsx(
                    classes.registerItemIndicator,
                    selectedOption === 1 ? classes.registerItemIndicatorActive : null
                  )}
                >
                  {selectedOption === 1 ? <div className={classes.registerItemIndicatorSelected} /> : null}
                </div>
                <div>Soy un proveedor, quiero matchear.</div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.actionsContainer}>
            <Button type="green" disabled={selectedOption === -1} onClick={handleOnSetRegistrationStep(1)}>
              {selectedOption === 0
                ? 'Registrate como cliente'
                : selectedOption === 1
                ? 'Registrate como proveedor'
                : 'Registrate'}
            </Button>
            <div className={classes.actionLink}>
              ¿Ya tenés una cuenta? <a href="/login">Ingresá</a>
            </div>
          </div>
        </React.Fragment>
      );
    }
    if (registrationStep === 1) {
      const form =
        selectedOption === 0 ? (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput label="Nombre" onChange={(e) => setInputFirstName(e.target.value)} value={inputFirstName} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput label="Apellido" onChange={(e) => setInputLastName(e.target.value)} value={inputLastName} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput label="Email" onChange={(e) => setInputEmail(e.target.value)} value={inputEmail} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                label="Contraseña"
                error={inputPasswordError}
                onChange={(e) => {
                  const password = e.target.value;
                  setInputPassword(password);
                  if (passwordRegex.test(password)) {
                    // Password is valid
                    setInputPasswordError(false);
                  } else {
                    // Password is invalid
                    setInputPasswordError(true);
                  }
                }}
                value={inputPassword}
                password={!showPassword}
                helperText={
                  inputPasswordError
                    ? 'Debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo válido (!@#$%^&*()-_=+[]{};:,.<>/?|~\\).'
                    : 'Debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo.'
                }
                endAdornment={
                  <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                options={countries}
                classes={{
                  option: classes.option,
                }}
                onChange={(e, value) => setInputCountry(value?.code)}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>
                    <span style={{ marginRight: 5 }}>{countryToFlag(option.code)} </span>
                    {option.name} ({option.code}) +{option.phone}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    label="País"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput label="Teléfono" onChange={(e) => setInputPhone(e.target.value)} value={inputPhone} />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput label="Nombre" onChange={(e) => setInputFirstName(e.target.value)} value={inputFirstName} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput label="Apellido" onChange={(e) => setInputLastName(e.target.value)} value={inputLastName} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                options={categories.map((option) => option.id)}
                getOptionLabel={(option) => categories.find((c) => c.id === option)?.name || ''}
                onChange={(e, value) => setInputCategories(value)}
                renderInput={(params) => <TextInput {...params} label="Área de trabajo" variant="outlined" />}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput label="Email de trabajo" onChange={(e) => setInputEmail(e.target.value)} value={inputEmail} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                label="Contraseña"
                error={inputPasswordError}
                onChange={(e) => {
                  const password = e.target.value;
                  setInputPassword(password);
                  if (passwordRegex.test(password)) {
                    // Password is valid
                    setInputPasswordError(false);
                  } else {
                    // Password is invalid
                    setInputPasswordError(true);
                  }
                }}
                value={inputPassword}
                password={!showPassword}
                helperText={
                  inputPasswordError
                    ? 'Debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo válido (!@#$%^&*()-_=+[]{};:,.<>/?|~\\).'
                    : 'Debe contener al menos 8 caracteres, una mayúscula, un número y un símbolo.'
                }
                endAdornment={
                  <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                options={countries}
                classes={{
                  option: classes.option,
                }}
                onChange={(e, value) => setInputCountry(value?.code)}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>
                    <span style={{ marginRight: 5 }}>{countryToFlag(option.code)} </span>
                    {option.name} ({option.code}) +{option.phone}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    label="País"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput label="Teléfono" onChange={(e) => setInputPhone(e.target.value)} value={inputPhone} />
            </Grid>
          </React.Fragment>
        );
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            {form}
          </Grid>
          <div className={clsx(classes.registerFormTerms, classes.actionLink)}>
            Al continuar estás aceptando los <a href="/terms">Términos y Condiciones</a> y la{' '}
            <a href="/privacy">Política de Privacidad</a> de Quiero Match.
          </div>
          <div className={classes.registerFormActions}>
            <Button type="outlined" onClick={handleOnSetRegistrationStep(0)}>
              Volver
            </Button>
            <Button type="green" onClick={submit} disabled={!canSubmitForm}>
              Crear cuenta
            </Button>
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <Page>
      <Header showSearchBar={false} withBorder />
      <div className={classes.container}>
        <div className={classes.title}>{renderTitle()}</div>
        <div className={classes.registerContainer}>{renderRegistrationSteps()}</div>
      </div>
    </Page>
  );
};

RegisterScreen.id = 'com.QuieroMatch.Register';

export default RegisterScreen;
