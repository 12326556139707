import React, { useEffect, useState } from 'react';
import { Button, Container, Header, MatchCard, Page, TextInput, Title } from '../../components';
import makeClasses from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { common as commonActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import _ from 'lodash';
import { convertProfileToWant } from '../../utils/Utils';
import clsx from 'clsx';

const MatchDetailsProviderScreen = ({ match }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { db, user } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();
  const matchId = match.params.matchId || null;

  const userData = useSelector((state) => state.user.data);

  const [matchData, setMatchData] = useState(null);
  const [matchProfileData, setMatchProfileData] = useState(null);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [selectedMatchProfileData, setSelectedMatchProfileData] = useState(null);
  const [showConfirmMatch, setShowConfirmMatch] = useState(false);

  useEffect(() => {
    if (user && matchId) {
      if (userData?.type === 0) {
        handleGoBack();
        return;
      }
      getMatchData();
    }
  }, [user, matchId, userData]);

  const getProfileData = async (profileId, matchId, matchData) => {
    const profileRef = await getDoc(doc(collection(db, 'users'), profileId));
    const profileData = { id: profileRef.id, ...profileRef.data() };
    return convertProfileToWant(profileRef.id, matchId, profileData, matchData);
  };

  const getMatchData = async () => {
    dispatch(commonActions.setLoading(true));

    try {
      const matchRef = await getDoc(doc(collection(db, 'matches'), matchId));
      const matchData = { id: matchRef.id, ...matchRef.data() };

      const matchProfileData = await getProfileData(matchData.user_id, matchRef.id, matchData);
      setMatchProfileData(matchProfileData);

      matchData.budget = matchData.budget?.find((b) => b.user_id === user.uid)?.budget || 'No especificado';

      setMatchData(matchData);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const goToSearch = (query) => {
    dispatch(push('/search?q=' + query));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handleOnClickCancel = () => {
    toggleShowConfirmCancelDialog();
  };

  const toggleShowConfirmCancelDialog = () => {
    setShowConfirmCancelDialog(!showConfirmCancelDialog);
  };

  const handleOnConfirmMatchCancellation = async () => {
    setShowConfirmCancelDialog(false);
    dispatch(commonActions.setLoading(true));
    try {
      await updateDoc(doc(db, 'matches', matchData.id), { status: 'rejected' });
      notifySuccess('Rechazaste la solicitud de match');

      setTimeout(() => {
        dispatch(push('/home?tab=0'));
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }
    dispatch(commonActions.setLoading(false));
  };

  const handleOnClickMatch = (profile) => () => {
    setShowConfirmMatch(true);
    setSelectedMatchProfileData(profile);
  };

  const handleOnConfirmMatch = async () => {
    setShowConfirmMatch(false);
    dispatch(commonActions.setLoading(true));
    try {
      await updateDoc(doc(db, 'matches', matchData.id), {
        status: 'approved',
        profile_id: user.uid,
        matches: [],
        matched_date: Date.now(),
      });
      notifySuccess('¡Genial! Hiciste match con ' + matchProfileData.first_name + ' ' + matchProfileData.last_name);

      setTimeout(() => {
        dispatch(push('/home?tab=2'));
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }
    dispatch(commonActions.setLoading(false));
  };

  const renderDetails = () => {
    if (!matchData) {
      return null;
    }

    const timePassed = moment().diff(moment(matchData.timestamp.toDate()), 'minutes');
    const timePassedFormatted = moment.duration(timePassed, 'minutes').humanize();

    return (
      <React.Fragment>
        {matchData?.type === 1 && matchData?.status === 'pending' ? (
          <Alert severity="info">
            Esta solicitud de match te la enviaron directamente, puedes aceptarla o rechazarla.
          </Alert>
        ) : null}
        {matchData?.type === 0 && matchData?.status === 'active' ? (
          <Alert severity="info">El usuario recibió tu solicitud de match, espera a que la acepte o rechace.</Alert>
        ) : null}
        <div className={classes.infoContainer}>
          {['approved'].includes(matchData.status) ? (
            <Title bold leftAligned size="sm">
              Información de tu match
            </Title>
          ) : (
            <Title bold leftAligned size="sm">
              Información de la solicitud de match
            </Title>
          )}

          <Grid container spacing={2}>
            <React.Fragment>
              <Grid item xs={12} sm={12} md={3}>
                <MatchCard
                  match={matchProfileData}
                  readOnly
                  fullProfile={['approved', 'completed'].includes(matchData.status)}
                />
              </Grid>
              {['approved', 'completed'].includes(matchData.status) ? (
                <Grid item xs={12} sm={12} md={9}>
                  <div className={classes.infoItem}>
                    <div className={classes.infoItemLabel}>Email</div>
                    <div className={classes.infoItemText}>{matchProfileData?.email}</div>
                  </div>
                  {matchProfileData?.phone ? (
                    <div className={classes.infoItem}>
                      <div className={classes.infoItemLabel}>Teléfono</div>
                      <div className={classes.infoItemText}>{matchProfileData?.phone}</div>
                    </div>
                  ) : null}
                </Grid>
              ) : null}
            </React.Fragment>
            <Grid item xs={12} sm={12} md={matchData.type === 1 ? 12 : 9}>
              <div className={classes.infoItem}>
                <div className={classes.infoItemLabel}>Fecha de solicitud</div>
                <div className={classes.infoItemText}>
                  {moment(matchData.timestamp.toDate()).format('DD, MMM YYYY')}
                </div>
              </div>
              {matchData.status === 'pending' || matchData.status === 'active' ? (
                <div className={classes.infoItem}>
                  <div className={classes.infoItemLabel}>Tiempo transcurrido desde tu solicitud</div>
                  <div className={classes.infoItemText}>{_.capitalize(timePassedFormatted)}</div>
                </div>
              ) : null}
              <div className={classes.infoItem}>
                <div className={classes.infoItemLabel}>Estado</div>
                <div className={classes.infoItemText}>
                  {matchData.status === 'pending'
                    ? 'Pendiente'
                    : matchData.status === 'active'
                    ? 'Activo'
                    : matchData.status === 'approved'
                    ? 'Matcheado'
                    : matchData.status === 'declined'
                    ? 'Rechazado'
                    : matchData.status === 'cancelled'
                    ? 'Cancelado'
                    : '-'}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {matchData.filters?.length > 0 ? (
          <div className={classes.infoContainer}>
            <Title bold leftAligned size="sm">
              Habilidades / Rubros / Actividades deseados
            </Title>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={clsx(classes.profileSectionContent, classes.profileSectionContentWithPills)}>
                  {matchData.filters.map((filter, index) => (
                    <div key={index} className={classes.profileSectionInfoItemPill}>
                      {filter}
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <div className={classes.infoContainer}>
          <Title bold leftAligned size="sm">
            Información del quiero
          </Title>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                label="Quiero"
                InputLabelProps={{
                  shrink: true,
                }}
                value={matchData.name}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                label="Descripción"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={15}
                value={matchData.description}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="Fecha límite"
                InputLabelProps={{
                  shrink: true,
                }}
                value={matchData.deadline ? moment(matchData.deadline).format('DD MMM YYYY') : '-'}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="¿Requiere presupuesto?"
                InputLabelProps={{
                  shrink: true,
                }}
                value={matchData.requires_budget ? 'Si' : 'No'}
                disabled
              />
            </Grid>
          </Grid>
        </div>
        {matchData.requires_budget ? (
          <div className={classes.infoContainer}>
            <Title bold leftAligned size="sm">
              Información del presupuesto
            </Title>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  label="Presupuesto"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={3}
                  value={matchData.budget}
                  disabled
                />
              </Grid>
            </Grid>
          </div>
        ) : null}
        <div className={classes.actionsWrapper}>
          <Button type="outlined" onClick={handleGoBack}>
            Volver
          </Button>
          {matchData.status === 'pending' ? (
            <div className={classes.multipleActionsContainer}>
              <Button type="outlined" variant="danger" onClick={handleOnClickCancel}>
                Rechazar solicitud de match
              </Button>
              <Button type="green" onClick={handleOnClickMatch(selectedMatchProfileData)}>
                Aceptar solicitud de match
              </Button>
            </div>
          ) : null}
        </div>
        <Dialog open={showConfirmCancelDialog} onClose={toggleShowConfirmCancelDialog}>
          <DialogTitle>Importante</DialogTitle>
          <DialogContent dividers>
            ¿Estas seguro que deseas rechazar tu solicitud de match? Esta acción no se puede deshacer.
          </DialogContent>
          <DialogActions>
            <Button type="outlined" onClick={toggleShowConfirmCancelDialog}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleOnConfirmMatchCancellation}>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showConfirmMatch} onClose={() => setShowConfirmMatch(false)}>
          <DialogTitle>Importante</DialogTitle>
          <DialogContent dividers>
            Al confirmar estarás haciendo match, te pondremos en contacto con {matchProfileData?.first_name}{' '}
            {matchProfileData?.last_name} y podrán concretar su quiero. Esta accion no se puede deshacer.
          </DialogContent>
          <DialogActions>
            <Button type="outlined" onClick={() => setShowConfirmMatch(false)}>
              Cancelar
            </Button>
            <Button type="green" onClick={handleOnConfirmMatch}>
              ¡Matchear!
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  return (
    <Page>
      <Header withBorder showSearchBar={false} />
      <Container>
        <div className={classes.container}>{renderDetails()}</div>
      </Container>
    </Page>
  );
};

MatchDetailsProviderScreen.id = 'com.QuieroMatch.MatchDetailsProvider';

export default MatchDetailsProviderScreen;
