import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    minHeight: '100%',
    padding: '100px 20px',
    width: 700,
    margin: '0 auto',
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  headerContainer: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  title: {
    color: '#333',
    margin: '15px 0',
    position: 'relative',
    fontSize: 32,
    textAlign: 'center',
    fontWeight: '400',
  },
  registerContainer: {
    marginTop: 30,
    width: '80%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  registerItem: {
    border: '2px solid rgba(0,0,0,0.1)',
    borderRadius: 10,
    padding: 24,
    color: '#333',
    fontSize: 20,
    fontWeight: '500',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    transition: 'all 0.3s ease',

    '&:hover': {
      background: '#f5f5f5',
      borderColor: '#FF6F00',
    },
  },
  registerItemActive: {
    background: '#f5f5f5',
    borderColor: '#FF6F00',
  },
  registerItemIndicator: {
    width: 25,
    height: 25,
    borderRadius: 25,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    alignSelf: 'flex-end',
    marginBottom: 10,
    transition: 'all 0.3s ease',
  },
  registerItemIndicatorActive: {
    background: '#FF6F00',
    border: '1px solid #f5f5f5',
  },
  registerItemIndicatorSelected: {
    width: 13,
    height: 13,
    borderRadius: 13,
    border: '1px solid #f5f5f5',
    position: 'absolute',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    flexDirection: 'column',
  },
  actionLink: {
    marginTop: 10,
    color: '#333',
    fontSize: 14,

    '& > a': {
      color: '#004D40',
    },
  },
  registerFormTerms: {
    marginTop: 15,
    marginBottom: 20,
    fontSize: 14,
  },
  registerFormActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default makeClasses;
