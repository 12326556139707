import { userTypes as types } from '../actions';

const initialState = {
  data: null,
  token: null,
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case types.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case types.CLEAR:
    case types.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
