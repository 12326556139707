import React, { useEffect, useState } from 'react';
import { Button, Container, Header, MatchCard, MatchProfile, Page, TextInput, Title } from '../../components';
import makeClasses from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { common as commonActions } from '../../actions';
import { useFirebase, useToasts } from '../../hooks';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import _ from 'lodash';
import { convertProfileToMatch } from '../../utils/Utils';
import clsx from 'clsx';

const MatchDetailsScreen = ({ match }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const { db, user } = useFirebase();
  const { notifySuccess, notifyError } = useToasts();
  const matchId = match.params.matchId || null;

  const userData = useSelector((state) => state.user.data);

  const [matchData, setMatchData] = useState(null);
  const [matchesProfileData, setMatchesProfileData] = useState(null);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [selectedMatchProfileData, setSelectedMatchProfileData] = useState(null);
  const [showConfirmMatch, setShowConfirmMatch] = useState(false);

  useEffect(() => {
    if (user && matchId) {
      if (userData?.type === 1) {
        handleGoBack();
        return;
      }

      getMatchData();
    }
  }, [user, matchId, userData]);

  const getProfileData = async (profileId) => {
    const profileRef = await getDoc(doc(collection(db, 'users'), profileId));
    const profileData = { id: profileRef.id, ...profileRef.data() };
    return convertProfileToMatch(profileRef.id, profileData);
  };

  const getMatchData = async () => {
    dispatch(commonActions.setLoading(true));

    try {
      const matchRef = await getDoc(doc(collection(db, 'matches'), matchId));
      const matchData = { id: matchRef.id, ...matchRef.data() };

      if (matchData.type === 0) {
        if (matchData.status === 'approved') {
          const matchesProfileData = await getProfileData(matchData.profile_id);
          setMatchesProfileData([matchesProfileData]);
        } else if (matchData.matches?.length > 0) {
          const matchesProfileData = [];
          for (const profileId of matchData.matches) {
            const profileData = await getProfileData(profileId);
            matchesProfileData.push(profileData);
          }
          setMatchesProfileData(matchesProfileData);
        }
      } else {
        const matchesProfileData = await getProfileData(matchData.profile_id);
        setMatchesProfileData([matchesProfileData]);
      }

      setMatchData(matchData);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }

    dispatch(commonActions.setLoading(false));
  };

  const goToSearch = (query) => {
    dispatch(push('/search?q=' + query));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handleOnClickCancel = () => {
    toggleShowConfirmCancelDialog();
  };

  const toggleShowConfirmCancelDialog = () => {
    setShowConfirmCancelDialog(!showConfirmCancelDialog);
  };

  const handleOnConfirmMatchCancellation = async () => {
    setShowConfirmCancelDialog(false);
    dispatch(commonActions.setLoading(true));
    try {
      await updateDoc(doc(db, 'matches', matchData.id), { status: 'cancelled' });
      notifySuccess('Tu solicitud de match fue cancelada');

      setTimeout(() => {
        dispatch(push('/home?tab=0'));
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }
    dispatch(commonActions.setLoading(false));
  };

  const handleOnClickProfile = (profile) => () => {
    setShowProfileInfo(true);
    setSelectedMatchProfileData(profile);
  };

  const handleOnClickMatch = (profile) => () => {
    setShowConfirmMatch(true);
    setSelectedMatchProfileData(profile);
  };

  const handleOnConfirmMatch = async () => {
    setShowConfirmMatch(false);
    dispatch(commonActions.setLoading(true));
    try {
      await updateDoc(doc(db, 'matches', matchData.id), {
        status: 'approved',
        profile_id: selectedMatchProfileData.id,
        matches: [],
        matched_date: Date.now(),
      });
      notifySuccess('¡Genial! Hiciste match con ' + selectedMatchProfileData.company_name);

      setTimeout(() => {
        dispatch(push('/home?tab=2'));
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }
    dispatch(commonActions.setLoading(false));
  };

  const handleOnClickComplete = async () => {
    dispatch(commonActions.setLoading(true));
    try {
      await updateDoc(doc(db, 'matches', matchData.id), {
        status: 'completed',
        completed_date: Date.now(),
      });
      notifySuccess('¡Genial! Tu match está completado');

      setTimeout(() => {
        dispatch(push('/rate-match/' + matchData.id));
      }, 500);
    } catch (error) {
      const errorMessage = error.message;
      notifyError(errorMessage);
    }
    dispatch(commonActions.setLoading(false));
  };

  const renderDetails = () => {
    if (!matchData) {
      return null;
    }

    const timePassed = moment().diff(moment(matchData.timestamp.toDate()), 'minutes');
    const timePassedFormatted = moment.duration(timePassed, 'minutes').humanize();

    return (
      <React.Fragment>
        {matchData?.type === 1 && matchData?.status === 'pending' ? (
          <Alert severity="info">
            Tu solicitud de match fue enviada, estamos a la espera de una respuesta por parte del proveedor.
          </Alert>
        ) : null}
        {['approved'].includes(matchData.status) ? (
          <Alert severity="info">
            Es importante que <strong>completes</strong> tu match para que puedas calificar su servicio y de esa forma
            ayudar a que mas personas puedan encontrar su quiero con este proveedor.
          </Alert>
        ) : null}
        {matchData.type === 0 ? (
          <div className={classes.infoContainer}>
            {['approved'].includes(matchData.status) ? (
              <div className={classes.titleWithButton}>
                <Title bold leftAligned size="sm">
                  Información de tu match
                </Title>
                <Button type="green" onClick={handleOnClickComplete}>
                  Completar
                </Button>
              </div>
            ) : (
              <div>
                <Title bold leftAligned size="sm">
                  {['approved', 'completed'].includes(matchData.status) ? (
                    <span>Información de tu match</span>
                  ) : (
                    <span>Candidatos para match</span>
                  )}
                </Title>
              </div>
            )}
            <Grid container spacing={2}>
              {matchesProfileData?.length > 0 ? (
                <React.Fragment>
                  {matchesProfileData?.map((matchProfileData, index) => (
                    <Grid item xs={12} sm={12} md={3} key={index}>
                      <MatchCard
                        match={matchProfileData}
                        onClickProfile={handleOnClickProfile(matchProfileData)}
                        onClickMatch={handleOnClickMatch(matchProfileData)}
                        showBudget={matchData.requires_budget}
                        budget={matchData.budget?.find((budget) => budget.user_id === matchProfileData.id)?.budget}
                        readOnly={['pending', 'cancelled', 'rejected', 'completed', 'approved'].includes(
                          matchData.status
                        )}
                        fullProfile={['approved', 'completed'].includes(matchData.status)}
                      />
                    </Grid>
                  ))}
                  {['approved', 'completed'].includes(matchData.status) ? (
                    <Grid item xs={12} sm={12} md={9}>
                      <div className={classes.infoItem}>
                        <div className={classes.infoItemLabel}>Email</div>
                        <div className={classes.infoItemText}>{matchesProfileData[0]?.email}</div>
                      </div>
                      {matchesProfileData[0]?.phone ? (
                        <div className={classes.infoItem}>
                          <div className={classes.infoItemLabel}>Teléfono</div>
                          <div className={classes.infoItemText}>{matchesProfileData[0]?.phone}</div>
                        </div>
                      ) : null}
                    </Grid>
                  ) : null}
                </React.Fragment>
              ) : (
                <Grid item xs={12} sm={12} md={12}>
                  <Alert severity="info">
                    Tu solicitud de match fue enviada, en cuanto algún proveedor la acepte lo podrás ver aquí.
                  </Alert>
                </Grid>
              )}
            </Grid>
          </div>
        ) : null}
        <div className={classes.infoContainer}>
          <div className={classes.titleWithButton}>
            {matchData.type === 1 && ['approved'].includes(matchData.status) ? (
              <React.Fragment>
                <Title bold leftAligned size="sm">
                  Información de tu match
                </Title>
                <Button type="green" onClick={handleOnClickComplete}>
                  Completar
                </Button>
              </React.Fragment>
            ) : (
              <Title bold leftAligned size="sm">
                Información de tu solicitud de match
              </Title>
            )}
          </div>
          <Grid container spacing={2}>
            {matchData.type === 1 ? (
              <Grid item xs={12} sm={12} md={3}>
                {matchesProfileData?.map((matchProfileData, index) => (
                  <MatchCard
                    key={index}
                    match={matchProfileData}
                    readOnly
                    onClickProfile={handleOnClickProfile(matchProfileData)}
                    fullProfile={['approved', 'completed'].includes(matchData.status)}
                  />
                ))}
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={matchData.type === 1 ? 12 : 9}>
              <div className={classes.infoItem}>
                <div className={classes.infoItemLabel}>Fecha de solicitud</div>
                <div className={classes.infoItemText}>
                  {moment(matchData.timestamp.toDate()).format('DD, MMM YYYY')}
                </div>
              </div>
              {matchData.status === 'pending' || matchData.status === 'active' ? (
                <div className={classes.infoItem}>
                  <div className={classes.infoItemLabel}>Tiempo transcurrido desde tu solicitud</div>
                  <div className={classes.infoItemText}>{_.capitalize(timePassedFormatted)}</div>
                </div>
              ) : null}
              <div className={classes.infoItem}>
                <div className={classes.infoItemLabel}>Estado</div>
                <div className={classes.infoItemText}>
                  {matchData.status === 'pending'
                    ? 'Pendiente'
                    : matchData.status === 'active'
                    ? 'Activo'
                    : matchData.status === 'approved'
                    ? 'Matcheado'
                    : matchData.status === 'declined'
                    ? 'Rechazado'
                    : matchData.status === 'cancelled'
                    ? 'Cancelado'
                    : '-'}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {matchData.filters?.length > 0 ? (
          <div className={classes.infoContainer}>
            <Title bold leftAligned size="sm">
              Habilidades / Rubros / Actividades deseados
            </Title>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={clsx(classes.profileSectionContent, classes.profileSectionContentWithPills)}>
                  {matchData.filters.map((filter, index) => (
                    <div key={index} className={classes.profileSectionInfoItemPill}>
                      {filter}
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <div className={classes.infoContainer}>
          <Title bold leftAligned size="sm">
            Información de tu quiero
          </Title>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                label="Quiero"
                InputLabelProps={{
                  shrink: true,
                }}
                value={matchData.name}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                label="Descripción"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={15}
                value={matchData.description}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="Fecha límite"
                InputLabelProps={{
                  shrink: true,
                }}
                value={matchData.deadline ? moment(matchData.deadline).format('DD MMM YYYY') : '-'}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="¿Requiere presupuesto?"
                InputLabelProps={{
                  shrink: true,
                }}
                value={matchData.requires_budget ? 'Si' : 'No'}
                disabled
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actionsWrapper}>
          <Button type="outlined" onClick={handleGoBack}>
            Volver
          </Button>
          {matchData.status === 'pending' || matchData.status === 'active' ? (
            <Button type="outlined" variant="danger" onClick={handleOnClickCancel}>
              Cancelar solicitud de match
            </Button>
          ) : null}
        </div>
        <MatchProfile
          open={showProfileInfo}
          onClose={() => setShowProfileInfo(false)}
          onMatch={handleOnClickMatch(selectedMatchProfileData)}
          profile={selectedMatchProfileData}
          readOnly={['pending', 'cancelled', 'rejected', 'completed', 'approved'].includes(matchData.status)}
          fullProfile={['approved', 'completed'].includes(matchData.status)}
          categoryId={matchData.category_id}
        />
        <Dialog open={showConfirmCancelDialog} onClose={toggleShowConfirmCancelDialog}>
          <DialogTitle>Importante</DialogTitle>
          <DialogContent dividers>
            ¿Estas seguro que deseas cancelar tu solicitud de match? Esta acción no se puede deshacer.
          </DialogContent>
          <DialogActions>
            <Button type="outlined" onClick={toggleShowConfirmCancelDialog}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleOnConfirmMatchCancellation}>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showConfirmMatch} onClose={() => setShowConfirmMatch(false)}>
          <DialogTitle>Importante</DialogTitle>
          <DialogContent dividers>
            Al confirmar estarás haciendo match, te pondremos en contacto con el proveedor y podrán concretar tu quiero.
            Esta accion no se puede deshacer.
          </DialogContent>
          <DialogActions>
            <Button type="outlined" onClick={() => setShowConfirmMatch(false)}>
              Cancelar
            </Button>
            <Button type="green" onClick={handleOnConfirmMatch}>
              ¡Matchear!
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  return (
    <Page>
      <Header withBorder showSearchBar={false} />
      <Container>
        <div className={classes.container}>{renderDetails()}</div>
      </Container>
    </Page>
  );
};

MatchDetailsScreen.id = 'com.QuieroMatch.MatchDetails';

export default MatchDetailsScreen;
