import React from 'react';
import clsx from 'clsx';
import makeClasses from './styles';

const Title = ({ size = 'md', bold, withLine, leftAligned, children }) => {
  const classes = makeClasses();

  return (
    <div
      className={clsx(
        classes.title,
        bold ? classes.titleBold : null,
        withLine ? classes.titleBoldWithLine : null,
        leftAligned ? classes.titleLeftAligned : null,
        size === 'sm' ? classes.titleSm : null
      )}
    >
      {children}
    </div>
  );
};

export default Title;
