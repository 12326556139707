import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
  container: {},
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
  },
}));

export default makeClasses;
