import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { Redirect } from 'react-router-dom';
import { useFirebase } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { user as userActions } from '../../actions';
import { doc, getDoc } from 'firebase/firestore';

import Landing from '../Landing';
import Login from '../Login';
import Register from '../Register';
import Search from '../Search';
import Profile from '../Profile';
import Home from '../Home';
import Match from '../Match';
import MatchByCategory from '../MatchByCategory';
import MatchDetails from '../MatchDetails';
import MatchDetailsProvider from '../MatchDetailsProvider';
import RateMatch from '../RateMatch';

const App = () => {
  const { auth, db, user, ready } = useFirebase();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    const getUserData = async () => {
      const docRef = await getDoc(doc(db, 'users', user.uid));
      dispatch(userActions.setData(docRef.data()));
    };
    if (auth && user) {
      getUserData();
    }
  }, [auth, user]);

  return (
    <Switch>
      <Route exact path="/" component={!userData || userData?.type === 0 ? Landing : Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/search" component={Search} />
      {ready ? (
        user?.uid ? (
          <React.Fragment>
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/match/:matchId/details" component={MatchDetails} />
            <Route exact path="/match-provider/:matchId/details" component={MatchDetailsProvider} />
            <Route exact path="/match/:profileId" component={Match} />
            <Route exact path="/match-by-category/:categoryId" component={MatchByCategory} />
            <Route exact path="/rate-match/:matchId" component={RateMatch} />
          </React.Fragment>
        ) : (
          <Redirect to="/login" />
        )
      ) : null}
    </Switch>
  );
};

export default App;
